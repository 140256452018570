import { createStyles, makeStyles } from '@material-ui/core';

export const IS_SAFARI = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

export const useStyles = makeStyles((theme) => createStyles({
  chartRoot: {
    width: '100%',
    height: '100vh',
    fontSize: 14,

    '@media (max-width: 840px)': {
      fontSize: 9
    },

    '& .rd3t-tree-container': {
      marginTop: 27
    }
  },
  foreignObject: {
    overflow: 'visible',
  },
  master: {
    height: 58,
    fontSize: 18
  },
  branchNode: {
    height: 'inherit',
    borderRadius: 10,
    padding: 5,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 10px 20px 0 ${theme.palette.frequent.boxShadow}`,
    overflow: 'visible',
    rx: 7,
    ry: 7,
    width: 200,
    opacity: 1,
  },
  branchNodeMaster: {
    width: 300,
    height: 80
  },
  branchNodeTerminated: {
    border: `solid 2px ${theme.palette.lineGraph.second}`
  },
  line: {
    stroke: '#cfcfcf !important'
  },
  expandIcon: {
    fontSize: '30px !important',
    lineHeight: '14px'
  },
  textDiv: {
    paddingTop: 16,
    display: 'block',
    paddingBottom: 9,
    lineHeight: '20px'
  },
  mobileChartLegend: {
    '@media (min-width: 1025px)': {
      display: 'none',
      opacity: 1,
      position: 'absolute',
      fontSize: 12
    }
  },
  chartLegend: {
    opacity: 1,
    top: '-5px',
    right: 15,
    position: 'absolute',

    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  mobileLegendRow: {
    display: 'inline-flex'
  },
  mobileItem: {
    paddingRight: 20
  },
  legendList: {
    listStyleType: 'none',
    paddingLeft: 20,
    marginTop: 10,
    textAlign: 'left',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  },
  legendListIcon: {
    width: 16,
    border: 'solid 1px #b8b8b8',
    height: 16,
    opacity: 1,
    flexGrow: 0,
    marginRight: 10,
    borderRadius: '50%',
    display: 'inline-block',

    '@media (max-width: 840px)': {
      marginRight: 6,
    }
  },
  legendMaster: {
    backgroundColor: theme.palette.primary.main
  },
  legendPartner: {
    backgroundColor: theme.palette.frequent.green
  },
  legendSubPartner: {
    backgroundColor: theme.palette.frequent.lightWildBlue
  },
  legendClient: {
    backgroundColor: theme.palette.frequent.lavander
  },
  legendTerminated: {
    backgroundColor: theme.palette.lineGraph.second,
  },
  rectangle: {
    height: 22,
    width: 'inherit',
    borderRadius: '10px 10px 0 0',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginTop: -4,
    marginLeft: -5,
    paddingLeft: 10,
    paddingTop: 2,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 400,

    '@media (max-width: 840px)': {
      fontSize: 11
    }
  },
  rectanglePartner: {
    backgroundColor: theme.palette.frequent.green
  },
  rectangleSubPartner: {
    backgroundColor: theme.palette.frequent.lightWildBlue
  },
  rectangleTerminated: {
    backgroundColor: theme.palette.lineGraph.second,
    marginTop: -6,
    marginLeft: -7,
  },
  rectangleClient: {
    backgroundColor: theme.palette.frequent.lavander
  },
  partnershipContent: {
    textAlign: 'center',
    fontSize: 12,
    padding: '19px 0px 30px',

    '@media (max-width: 840px)': {
      fontSize: 9
    }
  },
  partnershipCode: {
    fontSize: 18,
    color: theme.palette.primary.main
  },
  partnerName: {
    // fontSize: 14,
    marginBottom: 10,

    '@media (max-width: 840px)': {
      fontSize: 9
    }
  },
  clientsContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
    padding: '17px 10px 19px 20px',

    '@media (max-width: 840px)': {
      fontSize: 9,
      padding: '5px 20px 10px 20px',
    }
  },
  border: {
    borderRight: '2px solid #cfcfcf',
    height: 50
  },
  padding: {
    paddingTop: 8
  },
  viewAll: {
    width: 74,
    height: 20,
    fontSize: 12,
    color: theme.palette.frequent.lightWildBlue,
    borderRadius: 15,
    backgroundColor: theme.palette.frequent.lavander,
    border: 'none',
    cursor: 'pointer',

    '&:disabled': {
      cursor: 'default'
    },

    '@media (max-width: 840px)': {
      display: 'none'
    }
  },
  viewAllMobile: {
    width: 74,
    height: 20,
    fontSize: 9,
    color: theme.palette.frequent.lightWildBlue,
    borderRadius: 15,
    backgroundColor: theme.palette.frequent.lavander,
    border: 'none',
    cursor: 'pointer',

    '&:disabled': {
      cursor: 'default'
    },

    '@media (min-width: 841px)': {
      display: 'none'
    }
  }
}));
