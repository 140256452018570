import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { useAppData, useLoading } from '../../providers';
import { useStyles } from './styles';
import { RepresentativeSignUpForm } from './RepresentativeSignUpModel';
import {
  defaultLocale,
  extractLocaleFromPath,
  getTranslation,
  getURLParam,
  languages,
  PATH_LOGIN_AS_REPRESENTATIVE,
  supportedLocales,
} from '../../utils';
import { resetPassword } from './RepresentativeSignUpService';
import { AngleDownIcon } from '../../components';
import { getTranslationsByBrokerId } from '../Login/LoginService';

export const RepresentativeSignUp: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const { logoUrl, saveTranslations } = useAppData();
  const [logo, setLogo] = useState<string>('');
  const [translation, setTranslation] = useState<any>([]);
  const [selectedLocale, setSelectedLocale] = useState<any>(languages[0]);
  const location = useLocation();
  const lang = extractLocaleFromPath(location.pathname);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(translation.text_1366)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
        translation.text_1367,
      ),
    confirmPassword: Yup.string()
      .required(translation.text_1368)
      .oneOf([Yup.ref('password'), null], translation.text_1369),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RepresentativeSignUpForm>({
    resolver: yupResolver(validationSchema),
  });
  const { ref: passwordHookRef, ...passwordHookRefProps } =
    register('password');
  const { ref: confirmPasswordHookRef, ...confirmPasswordHookRefProps } =
    register('confirmPassword');

  const email = getURLParam('Email');
  const resetToken = getURLParam('Token');
  const brokerId = getURLParam('BrokerId');

  const getTranslationList = useCallback(
    async (locale: string, broker: string) => {
      const translations = await getTranslationsByBrokerId(locale, broker);
      saveTranslations(JSON.stringify(translations.data));
      setTranslation(translations.data);
    },
    [saveTranslations],
  );

  const handleLocaleChange = useCallback(
    async (event: React.ChangeEvent<{ value: unknown }>) => {
      const selectedLanguage =
        languages.find(
          (item) => item.value === (event.target.value as string),
        ) || languages[0];
      setSelectedLocale(selectedLanguage);
      await getTranslationList(selectedLanguage.value, brokerId);
    },
    [getTranslationList, brokerId],
  );

  useEffect(() => {
    if (brokerId) {
      (async () => {
        if (lang && supportedLocales.includes(lang)) {
          const selectedLanguage =
            languages.find((item) => item.value === lang) || languages[0];
          setSelectedLocale(selectedLanguage);
          await getTranslationList(lang, brokerId);
        } else {
          await getTranslationList(defaultLocale, brokerId);
        }
      })();
    }
  }, [getTranslationList, brokerId, lang]);

  useEffect(() => {
    setLogo(logoUrl);
  }, [logoUrl]);

  const signUp = useCallback(
    async (data: RepresentativeSignUpForm) => {
      setIsLoading(true);

      try {
        const response = await resetPassword({
          email,
          password: data.password,
          resetToken,
        });
        if (response.data.serviceResultStatus === 1) {
          history.push(PATH_LOGIN_AS_REPRESENTATIVE);
        } else {
          enqueueSnackbar(getTranslation(translation, response.data.message), {
            variant: 'error',
          });
        }
      } catch (error) {
        const errorData = error.response.data;
        if (
          errorData
            .toLowerCase()
            .indexOf('unhandled exception occurred when trying to login') > -1
        ) {
          enqueueSnackbar(getTranslation(translation, errorData), {
            variant: 'error',
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, email, resetToken, history, enqueueSnackbar, translation],
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="row-reverse">
        <FormControl variant="standard" className={classes.languageSelect}>
          <Select
            onChange={handleLocaleChange}
            renderValue={(value: any) => (
              <div className={classes.languageItem}>
                <span className={classes.languageSelectedItem}>
                  {value.icon}
                </span>
                {value.value}
              </div>
            )}
            autoWidth
            inputProps={{
              IconComponent: AngleDownIcon,
            }}
            value={selectedLocale}
            disableUnderline
          >
            {languages.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <span className={classes.languageSelectItem}>{item.icon}</span>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Container className={classes.container} maxWidth="xs">
        <form className={classes.signUpForm} onSubmit={handleSubmit(signUp)}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.logo}>
                <img src={logo} alt="" className={classes.logoImg} />
              </div>
              <Typography variant="h6" className={classes.header}>
                {translation.text_1370}
              </Typography>
              <div>
                <InputLabel className={classes.label}>
                  {translation.text_1371}
                </InputLabel>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="email"
                  type="email"
                  margin="dense"
                  variant="outlined"
                  value={email}
                  placeholder={translation.text_1376}
                  disabled
                />
                <InputLabel className={`${classes.padding} ${classes.label}`}>
                  {translation.text_1372}
                  <span className={classes.required}>*</span>
                </InputLabel>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="password"
                  margin="dense"
                  variant="outlined"
                  placeholder={translation.text_1377}
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  inputRef={passwordHookRef}
                  {...passwordHookRefProps}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <InputLabel className={`${classes.padding} ${classes.label}`}>
                  {translation.text_1373}
                  <span className={classes.required}>*</span>
                </InputLabel>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  margin="dense"
                  variant="outlined"
                  placeholder={translation.text_1374}
                  error={!!errors.confirmPassword}
                  helperText={errors?.confirmPassword?.message}
                  inputRef={confirmPasswordHookRef}
                  {...confirmPasswordHookRefProps}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                type="submit"
                variant="contained"
                className={classes.signUpBtn}
                disabled={isLoading}
              >
                {translation.text_1375}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Container>
    </Grid>
  );
};
