import { instanceApi } from '../../../services';
import { AllClientsAndTradingEmailReport } from './AllClientsAndTradingModel';

export const getAllClientsAndTradingReportDetail = (
  partnershipCode: string,
  partnerID: number,
  month: number,
  year: number,
  day: number,
  toMonth: number,
  toYear: number,
  toDay: number,
  currency: string,
  accountGuid: string,
): Promise<any> =>
  instanceApi.get(
    `rebate/report/allclientsandtradingreport?PartnershipCode=${partnershipCode}&PartnerId=${partnerID}&Month=${month}&Year=${year}
    &Day=${day}&EndMonth=${toMonth}&EndYear=${toYear}&EndDay=${toDay}&ConvertToCurrency=${currency}&RequesterAccountGuid=${accountGuid}`,
  );

export const getAllClientsAndTradingReports = (
  partnerID: number,
  month: number,
  year: number,
  day: number,
  toMonth: number,
  toYear: number,
  toDay: number,
  currency: string,
  accountGuid: string,
): Promise<any> =>
  instanceApi.get(
    `rebate/report/getcommissionbyclientreport?PartnerId=${partnerID}&Month=${month}&Year=${year}&Day=${day}&EndMonth=${toMonth}&EndYear=${toYear}&EndDay=${toDay}&ConvertToCurrency=${currency}&RequesterAccountGuid=${accountGuid}`,
  );

export const sendEmailClientsAndTradingByRequestId = (
  data: AllClientsAndTradingEmailReport,
): Promise<any> =>
  instanceApi.post('rebate/report/sendemailcommissionsclientbyrequestid', data);

export const sendEmailClientsAndTradingDetails = (
  data: AllClientsAndTradingEmailReport,
): Promise<any> =>
  instanceApi.post('/rebate/report/sendemailsummarybyclientreport', data);
