import React, { FC, useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Grid, useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import { ChartData, LineChartData } from './LineChartModel';
import { GreenArrowIcon, GreyLineIcon, RedArrowIcon } from '../../CustomIcons';
import { formatNumber, getTranslation } from '../../../utils';
import { useAppData } from '../../../providers';

interface Props {
  period: string;
  currency?: string;
  data: ChartData[];
  colors: any;
  translation: any;
}

export const LineChart: FC<Props> = ({
  currency,
  data,
  period,
  colors,
  translation,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const [series, setSeries] = useState<any[]>([]);
  const [chartPosition, setChartPosition] = useState<number>(100);
  // const [bottomTickRotation, setBottomTickRotation] = useState<number>(0);
  const [maxY, setMaxY] = useState<number>();
  const { locale } = useAppData();
  // const [localeString, setLocaleString] = useState<string>('en');

  const chartTheme = {
    grid: {
      line: {
        stroke: `${theme.palette.lineGraph.text}8F`,
      },
    },
    axis: {
      ticks: {
        text: {
          fill: theme.palette.lineGraph.text,
          fontSize: 13,
        },
      },
    },
  };

  useEffect(() => {
    const formattedSeries: any = [];
    const allData: LineChartData[] = [];

    data.forEach((element) => {
      const chartData = element.data;

      if (period === 'Month') {
        chartData.forEach((item) => {
          const periodArr = item.period.split('-');
          if (periodArr.length > 1) {
            const month = new Date(
              new Date().setMonth(Number(periodArr[1]) - 1),
            ).toLocaleString(locale, { month: 'short' });
            item.period = `${month} ${periodArr[0]}`;
          }
        });
        // setBottomTickRotation(-45);
      }

      if (chartData.length < 2) {
        chartData.unshift({
          period: '0',
          amount: 0,
        });
      }

      allData.push(...chartData);

      const formattedData = chartData.map((reading) => ({
        x: reading.period,
        y: reading.amount,
        increased: reading.increased,
        decreased: reading.decreased,
        active: reading.active,
        dormant: reading.dormant,
      }));

      formattedSeries.push({
        id: element.id,
        color: element.color,
        data: formattedData,
      });
    });

    setSeries(formattedSeries);

    const yValues = allData.map((d) => d.amount);
    const maxValue = yValues.reduce((v1, v2) => (v1 > v2 ? v1 : v2));
    if (maxValue < 100) {
      setChartPosition(50);
    }
    if (maxValue === 0) {
      setMaxY(5);
    }
  }, [data, currency, period, locale]);

  const toolTipElement = (props: any) => {
    let className = '';
    if (props.point.serieColor === theme.palette.lineGraph.first) {
      className = classes.toolTipColorFirst;
    } else if (props.point.serieColor === theme.palette.lineGraph.second) {
      className = classes.toolTipColorSecond;
    } else if (props.point.serieColor === theme.palette.lineGraph.third) {
      className = classes.toolTipColorThird;
    } else {
      className = classes.toolTipColorFourth;
    }

    return (
      <div className={`${classes.toolTip} ${className}`}>
        <Grid container direction="column" alignItems="flex-start">
          <Grid item style={{ textTransform: 'capitalize' }}>
            <span style={{ fontWeight: 500 }}>
              {getTranslation(translation, period)}
            </span>
            : {props.point.data.x.toString()}
          </Grid>
          <Grid container direction="row">
            <Grid item>
              <Grid container direction="column" alignItems="flex-start">
                <Grid item style={{ paddingTop: 10, fontWeight: 500 }}>
                  {getTranslation(translation, props.point.serieId)}
                </Grid>
                <Grid item style={{ fontSize: 11 }}>
                  <span style={{ fontSize: 17 }}>
                    {currency
                      ? Number(props.point.data.y).toLocaleString('en-US', {
                          style: 'currency',
                          currency,
                        })
                      : props.point.data.y}
                  </span>
                  &nbsp;
                  {props.point.data.increased ? (
                    <>
                      <GreenArrowIcon className={classes.tooltipIcon} />{' '}
                      {props.point.data.increased}%
                    </>
                  ) : props.point.data.decreased ? (
                    <>
                      <RedArrowIcon className={classes.tooltipIcon} />{' '}
                      {props.point.data.decreased}%
                    </>
                  ) : (
                    <GreyLineIcon className={classes.tooltipNoChangeIcon} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {props.point.serieId === 'Accounts' && (
              <Grid item style={{ paddingLeft: 20 }}>
                <Grid item style={{ paddingTop: 10 }}>
                  <span style={{ fontWeight: 500 }}>
                    {translation.text_1596}
                  </span>{' '}
                  {props.point.data.active}
                </Grid>
                <Grid item style={{ paddingTop: 5 }}>
                  <span style={{ fontWeight: 500 }}>
                    {translation.text_1597}
                  </span>{' '}
                  {props.point.data.dormant}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <div className={classes.chartRoot}>
        <ResponsiveLine
          curve="linear"
          data={series}
          theme={chartTheme}
          colors={colors}
          enableGridY
          enableGridX={false}
          margin={{
            top: 14,
            right: 30,
            bottom: 60,
            left: chartPosition,
          }}
          yScale={{
            type: 'linear',
            min: 0,
            max: maxY || 'auto',
            stacked: false,
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            // tickRotation: bottomTickRotation,
          }}
          axisLeft={{
            tickValues: 4,
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            format: (value) =>
              currency ? formatNumber(value, currency) : value,
          }}
          enableCrosshair={false}
          lineWidth={2}
          pointSize={13}
          pointColor={theme.palette.common.white}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh
          tooltip={toolTipElement}
        />
      </div>
    </>
  );
};
