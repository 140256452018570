/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Badge, Button, Grid, IconButton, Menu } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useStyles } from './styles';
import { convertUTCDateToLocal, PATH_NOTIFICATIONS } from '../../utils';
import { NotificationMenuItem } from './components';
import { getNotifications } from '../../pages';
import { useApiError, useAppData, useLoading } from '../../providers';
import { Notification } from '../../models';
import { NotificationBellIcon, UserIcon } from '../CustomIcons';

export const NotificationsMenu: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setIsLoading } = useLoading();
  const { addError } = useApiError();
  const { translations } = useAppData();
  const [data, setData] = useState([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [countUnread, setCountUnread] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const countNotifications = (notifies: any) => {
    let unreadNumber = 0;
    notifies.forEach((item: any) => {
      if (!item.isRead) {
        unreadNumber += 1;
      }
    });

    setCountUnread(unreadNumber);
  };

  const getNotificationList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getNotifications();
      setNotifications(response.data);
      const notifies: any = [];

      response.data.forEach((notification: Notification) => {
        const notify = {
          message: notification.message.subject,
          notification,
          detailedPage: '',
          receivedTime: convertUTCDateToLocal(
            notification.dateCreated,
            'DD/MM/yyyy hh:mm',
          ),
          isRead: notification.isRead,
        };

        notifies.push(notify);
      });
      setData(notifies);
      countNotifications(notifies);
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [addError, setIsLoading]);

  useEffect(() => {
    (async () => {
      await getNotificationList();
    })();
  }, []);

  const redirectToNotificationsPage = useCallback(() => {
    handleClose();
    history.push(PATH_NOTIFICATIONS, notifications);
  }, [history, notifications, handleClose]);

  return (
    <Grid item className={classes.root}>
      <IconButton className={classes.btn} onClick={handleClick}>
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={
            <NotificationBellIcon
              className={
                countUnread > 0
                  ? classes.newNotificationIcon
                  : classes.notificationIcon
              }
            />
          }
        >
          <UserIcon className={classes.userIcon} />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.container}
      >
        <Grid container direction="row" className={classes.header}>
          <Grid item>{translation.text_1615}</Grid>
          <Button color="primary" onClick={redirectToNotificationsPage}>
            {translation.text_1616}
          </Button>
        </Grid>
        {data.length > 0 ? (
          data.map((item: any) => (
            <NotificationMenuItem notification={item} closeMenu={handleClose} />
          ))
        ) : (
          <Grid className={classes.noNotifications}>
            {translation.text_1617}
          </Grid>
        )}
      </Menu>
    </Grid>
  );
};
