import React, { FC, useCallback } from 'react';
import { MobileTableView } from '../../../../../components';
import { ValueLabel } from '../../../../../models';
import { TransactionsReportData } from '../../TransactionsReportModel';

interface Props {
  selectedColumns: string[];
  reportColumns: string[];
  rows: TransactionsReportData[];
}

export const MobileReportTable: FC<Props> = ({
  selectedColumns,
  reportColumns,
  rows,
}) => {
  const renderRows = useCallback((): any[] => {
    const result: any[] = [];

    if (rows.length === 0) {
      const rowData: ValueLabel[] = [];
      reportColumns.forEach((columnName) => {
        const isSelected = selectedColumns.includes(columnName);
        if (isSelected) {
          rowData.push({
            value: '',
            label: columnName,
          });
        }
      });
      result.push(rowData);
    }

    rows.forEach((row) => {
      const rowData: ValueLabel[] = [];

      if (selectedColumns.includes(reportColumns[0])) {
        rowData.push({
          value:
            row.accountPartnership || row.accountPartnership === ''
              ? row.accountPartnership
              : '-',
          label: reportColumns[0],
        });
      }
      if (selectedColumns.includes(reportColumns[1])) {
        rowData.push({
          value:
            row.accountName || row.accountName === '' ? row.accountName : '-',
          label: reportColumns[1],
        });
      }
      if (selectedColumns.includes(reportColumns[2])) {
        rowData.push({
          value: row.login || row.login === 0 ? row.login : '-',
          label: reportColumns[2],
        });
      }
      if (selectedColumns.includes(reportColumns[3])) {
        rowData.push({
          value:
            row.deposits || row.deposits === ''
              ? Number(row.deposits).toFixed(2)
              : '-',
          label: reportColumns[3],
        });
      }
      if (selectedColumns.includes(reportColumns[4])) {
        rowData.push({
          value:
            row.withdrawals || row.withdrawals === ''
              ? Number(row.withdrawals).toFixed(2)
              : '-',
          label: reportColumns[4],
        });
      }
      if (selectedColumns.includes(reportColumns[5])) {
        rowData.push({
          value:
            row.netDeposit || row.netDeposit === ''
              ? Number(row.netDeposit).toFixed(2)
              : '-',
          label: reportColumns[5],
        });
      }
      result.push(rowData);
    });

    return result;
  }, [selectedColumns, rows, reportColumns]);

  return (
    <MobileTableView
      list={renderRows()}
      grouped={false}
      hasNextButton={false}
    />
  );
};
