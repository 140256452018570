import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      height: 233,
      marginBottom: 20,
      textAlign: 'center',
      boxShadow: `0 10px 20px 0 ${theme.palette.frequent.boxShadow}`,
      cursor: 'pointer',
      '&:hover $rectangle': {
        backgroundColor: theme.palette.changeable.topPanelBorderColor,
      },

      '@media (max-width: 1180px)': {
        height: 215,
      },

      '@media (max-width: 427px)': {
        height: 175,
      },
    },
    paperSelected: {
      '& $rectangle': {
        backgroundColor: theme.palette.changeable.topPanelBorderColor,
      },
      cursor: 'pointer',
    },
    rectangle: {
      height: 10,
      width: '100%',
      marginBottom: 56,
      borderRadius: '6px 6px 0 0',
      backgroundColor: theme.palette.frequent.grey,

      '@media (max-width: 427px)': {
        marginBottom: 25,
      },
    },
    paperTitles: {
      fontSize: 18,
      paddingBottom: 6,
      color: theme.palette.changeable.topPanelBorderColor,
      fontWeight: 600,

      '@media (max-width: 1180px)': {
        fontSize: 17,
        fontWeight: 'normal',
      },

      '@media (max-width: 680px)': {
        fontSize: 15,
      },
    },
    values: {
      fontSize: 24,
      fontWeight: 700,

      '@media (max-width: 1180px)': {
        fontSize: 20,
        fontWeight: 'normal',
      },

      '@media (max-width: 680px)': {
        fontSize: 15,
      },
    },
    dailyValues: {
      fontSize: 16,
      color: theme.palette.frequent.lightLinkGrey,

      '@media (max-width: 1180px)': {
        fontSize: 12,
      },

      '@media (max-width: 680px)': {
        fontSize: 10,
      },
    },
  }),
);
