import { instanceApi } from '../../../services';
import {
  DailyReportRequestData, MonthlyReportRequestData, MonthlyEmailReportRequest, DailyEmailReportRequest
} from './TradingSummarytModel';

export const getTradingDailyReport = (accountGuid: string | undefined, data: DailyReportRequestData): Promise<any> => instanceApi.get(
  `api/AnalysisReport/TradingDailyReport?accountGuidAuth=${accountGuid}&Email=${data.email}&brokerDomainId=${data.brokerDomainId}&PartnershipCode=${data.partnershipCode}&DayFrom=${data.dayFrom}&DayTo=${data.dayTo}`
  + `&YearFrom=${data.yearFrom}&YearTo=${data.yearTo}&MonthFrom=${data.monthFrom}&MonthTo=${data.monthTo}&FilterPlatformType=${data.filterPlatformType}&FilterAccountType=${data.accountTypeFilter}`
);

export const getTradingMonthlyReport = (accountGuid: string | undefined, data: MonthlyReportRequestData): Promise<any> => instanceApi.get(
  `api/AnalysisReport/TradingMonthlyReport?accountGuidAuth=${accountGuid}&Email=${data.email}&brokerDomainId=${data.brokerDomainId}&PartnershipCode=${data.partnershipCode}&YearFrom=${data.yearFrom}&YearTo=${data.yearTo}`
  + `&MonthFrom=${data.monthFrom}&MonthTo=${data.monthTo}&FilterPlatformType=${data.filterPlatformType}&FilterAccountType=${data.accountTypeFilter}`
);

export const sendEmailTradingMonthlySummary = (data: MonthlyEmailReportRequest): Promise<any> => instanceApi.post('api/analysisreport/sendemailtradingmonthlysummary', data);

export const sendEmailTradingDailySummary = (data: DailyEmailReportRequest): Promise<any> => instanceApi.post('api/analysisreport/sendemailtradingdailysummary', data);
