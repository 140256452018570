import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      textAlign: 'left',
      fontSize: 28,
      fontWeight: 500,
      color: theme.palette.frequent.davyGrey,
    },
    paper: {
      marginTop: 10,
      padding: 15,
    },
    paperNew: {
      minHeight: '100vh',
    },
    panelRow: {
      padding: '16px 0px 53px 0px',

      '@media (max-width: 719px)': {
        display: 'none',
      },
    },
    mobilePanelRow: {
      padding: '16px 0px 53px 0px',

      '@media (min-width: 720px)': {
        display: 'none',
      },
    },
    menuButton: {
      backgroundColor: theme.palette.changeable.reportItemBgColor,
      borderRadius: 10,
      boxShadow: 'none',
      width: '100%',
      height: 80,
      textAlign: 'left',
      justifyContent: 'flex-start',
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.frequent.nickel,

      '&:hover': {
        backgroundColor: theme.palette.changeable.reportItemBgColor,
      },

      '& .MuiButton-startIcon': {
        marginLeft: 0,
      },

      '@media (max-width: 720px)': {
        height: 55,
        boxShadow: 'none',
      },

      '@media (max-width: 380px)': {
        fontSize: 16,
      },
    },
    menuButtonText: {
      marginLeft: 19,
      fontWeight: 'normal',

      '@media (max-width: 720px)': {
        marginLeft: 16,
      },
    },
    circleContainer: {
      paddingTop: 2,
      paddingLeft: 9,
    },
  }),
);
