/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { getTranslation, languages } from 'src/utils';
import { LoginForm } from './LoginModels';
import { AngleDownIcon, CircleIcon } from '../../components';
import { useStyles } from './LoginStyles';
import {
  getUserLoginData,
  loginRepresentative,
  getTranslationsByBrokerId,
  getTranslations,
} from './LoginService';
import { useApiError, useAppData, useLoading } from '../../providers';
import { clearThemeCache } from '../../layouts';

export const LoginAsRepresentative: FC = () => {
  const {
    pagePath,
    saveUserData,
    saveToken,
    removeToken,
    saveTranslations,
    authToken,
    translations,
    saveLocale,
    locale,
  } = useAppData();
  const history = useHistory();
  const { isLoading, setIsLoading } = useLoading();
  const classes = useStyles();
  const { addError } = useApiError();
  const { enqueueSnackbar } = useSnackbar();
  const [showError, setShowError] = useState<boolean>(false);
  const [translation, setTranslation] = useState<any>([]);
  const [selectedLocale, setSelectedLocale] = useState<any>(languages[0]);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginForm>();
  const { ref: emailHookRef, ...emailHookRefProps } = register('userName', {
    required: translation.text_1358,
  });
  const { ref: passwordHookRef, ...passwordHookRefProps } = register(
    'password',
    {
      required: translation.text_1359,
    },
  );

  const getTranslationList = useCallback(
    async (newLocale: string) => {
      try {
        setIsLoading(true);
        const brokerID = process.env.REACT_APP_BROKER_ID;
        const translations = await getTranslationsByBrokerId(
          newLocale,
          brokerID,
        );
        saveTranslations(JSON.stringify(translations.data));
        setTranslation(translations.data);
        saveLocale(newLocale);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [saveTranslations, saveLocale, addError, setIsLoading],
  );

  const handleLocaleChange = useCallback(
    async (event: React.ChangeEvent<{ value: unknown }>) => {
      const selectedLanguage =
        languages.find(
          (item) => item.value === (event.target.value as string),
        ) || languages[0];
      setSelectedLocale(selectedLanguage);
      await getTranslationList(selectedLanguage.value);
    },
    [getTranslationList],
  );

  useEffect(() => {
    const defaultLocale = process.env.REACT_APP_DEFAULT_LOCALE || 'en';
    if (locale) {
      const selectedLanguage =
        languages.find((item) => item.value === locale) || languages[0];
      setSelectedLocale(selectedLanguage);
    }
    (async () => {
      await getTranslationList(locale || defaultLocale);
    })();
  }, []);

  const setPath = useCallback(
    (userLoginData: any) => {
      const routesArray: string[] = [];
      let defaultRoute = '';

      if (userLoginData.accounts != null && userLoginData.accounts.length > 0) {
        // All accounts
        const accountCollection = userLoginData.accounts;

        // Loop each account
        accountCollection.forEach((account: any) => {
          const { routeCollection } = account.role;
          defaultRoute = routeCollection.defaultRoute.route;
          const routeCollectionArray = routeCollection.routeCollectionHasRoute;

          routeCollectionArray.forEach((routeIndex: any) => {
            routesArray.push(routeIndex.route);
          });
        });
      }

      let path = defaultRoute;
      if (pagePath) {
        const exist =
          routesArray.filter(
            (route) => route === pagePath || route.indexOf(pagePath) > -1,
          ).length > 0;
        path = exist ? pagePath : defaultRoute;
      }

      history.push(path);
    },
    [history, pagePath],
  );

  const getLoginData = useCallback(async () => {
    try {
      const response = await getUserLoginData();
      const userData = response.data;
      if (
        userData.accounts[0].userRoleName !== 'Partner Representative' &&
        userData.accounts[0].role.id !== 8
      ) {
        removeToken();
        setShowError(true);
      } else {
        saveUserData(JSON.stringify(userData));
        setPath(userData);
      }
    } catch (error) {
      const errorData = error.response ? error.response.data : error;
      enqueueSnackbar(getTranslation(JSON.parse(translations), errorData), {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, saveUserData, setPath, removeToken]);

  useEffect(() => {
    if (authToken) {
      (async () => {
        const translations = await getTranslations(selectedLocale || 'en');
        saveTranslations(JSON.stringify(translations.data));
        await getLoginData();
      })();
    }
  }, [authToken, getLoginData, saveTranslations]);

  const loginAsRepresentative = useCallback(
    async (data: LoginForm) => {
      setIsLoading(true);
      setShowError(false);

      try {
        const response = await loginRepresentative(data);
        if (response.data.serviceResultStatus === 1) {
          saveToken(response.data.result);
          await clearThemeCache();
        } else {
          enqueueSnackbar(
            getTranslation(JSON.parse(translations), response.data.message),
            {
              variant: 'error',
            },
          );
        }
      } catch (error) {
        const errorData = error.response.data;
        if (
          errorData
            .toLowerCase()
            .indexOf('unhandled exception occurred when trying to login') > -1
        ) {
          enqueueSnackbar(getTranslation(JSON.parse(translations), errorData), {
            variant: 'error',
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, setIsLoading, saveToken],
  );

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="row-reverse">
        <FormControl variant="standard" className={classes.languageSelect}>
          <Select
            onChange={handleLocaleChange}
            renderValue={(value: any) => (
              <div className={classes.languageItem}>
                <span className={classes.languageSelectedItem}>
                  {value.icon}
                </span>
                {value.value}
              </div>
            )}
            autoWidth
            inputProps={{
              IconComponent: AngleDownIcon,
            }}
            value={selectedLocale}
            disableUnderline
          >
            {languages.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <span className={classes.languageSelectItem}>{item.icon}</span>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Container className={classes.container} maxWidth="xs">
        <form
          className={classes.loginForm}
          onSubmit={handleSubmit(loginAsRepresentative)}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" className={classes.header}>
                {translation.text_1360}{' '}
                <span className={classes.systemName}>
                  {translation.text_1361}
                </span>
              </Typography>
              {showError && (
                <div className={classes.errorMessage}>
                  <CircleIcon height={19} /> {translation.text_1362}
                </div>
              )}
              <div>
                <InputLabel className={classes.label}>
                  {translation.text_1363}
                </InputLabel>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="email"
                  type="email"
                  margin="dense"
                  variant="outlined"
                  error={!!errors.userName}
                  helperText={errors?.userName?.message}
                  inputRef={emailHookRef}
                  {...emailHookRefProps}
                />
                <InputLabel className={`${classes.padding} ${classes.label}`}>
                  {translation.text_1364}
                </InputLabel>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="password"
                  type="password"
                  margin="dense"
                  variant="outlined"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  inputRef={passwordHookRef}
                  {...passwordHookRefProps}
                />
              </div>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                className={classes.loginBtn}
                disabled={isLoading}
              >
                {translation.text_1365}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Container>
    </Grid>
  );
};
