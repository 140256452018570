import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
    padding: '0 25px 0',
    marginBottom: 30,
    backgroundColor: theme.palette.frequent.background,
    borderRadius: 10,

    '@media (max-width: 419px)': {
      padding: '0 15px 0x',
    }
  },
  dataColumn: {
    width: '43%',
    flexBasis: '43%'
  },
  arrowColumn: {
    width: '6%',
    flexBasis: '6%'
  },
  row: {
    marginTop: 20
  },
  groupByLabel: {
    textAlign: 'left',
    textTransform: 'uppercase',
    color: theme.palette.frequent.slateGrey,
    marginRight: 9
  },
  label: {
    textAlign: 'left',
    textTransform: 'uppercase',
    color: theme.palette.frequent.slateGrey
  },
  value: {
    textAlign: 'left',
    fontWeight: 500,
    height: 21
  },
  divider: {
    backgroundColor: theme.palette.secondary.light,
    margin: '23px 0 3px',
    height: 1
  },
  arrowIcon: {
    color: theme.palette.frequent.cadetBlue
  },
}));
