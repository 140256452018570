import { makeStyles } from '@material-ui/core';

export const useCommonStyles = makeStyles((theme) => ({
  tableHeader: {
    textTransform: 'uppercase',
    fontWeight: 400,
    backgroundColor: theme.palette.changeable.tableHeaderBgColor,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.frequent.ghostWhite,
    },
  },
  button: {
    padding: '10px 20px',
    textTransform: 'none',
  },
  buttonContained: {
    padding: '10px 20px',
    textTransform: 'none',
    width: '100%',
  },
  buttonOutlined: {
    textTransform: 'none',
  },
  collapsibleRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  linkButtons: {
    width: '100%',
    height: 40,

    '@media (max-width: 640px)': {
      fontSize: 13,
      fontWeight: 'normal',
      height: 35,
    },

    '@media (max-width: 427px)': {
      fontSize: 12,
    },
  },
  newButtonContainer: {
    textAlign: 'right',
  },
}));
