import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    LogoContainer: {
      backgroundColor: theme.palette.changeable.layoutBackground,
      borderRadius: 10,
      margin: '20px 80px 0px 33px',
      width: 250,
      height: 94,
      display: 'flex',
      alignItems: 'center',
      padding: '35px 20px',

      '@media (max-width: 1180px)': {
        width: 84,
        height: 'auto',
        margin: 0,
        padding: 0,
      },
    },
    logoImg: {
      width: 200,
      '@media (max-width: 1024px)': {
        width: 84,
      },
    },
  }),
);
