import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormControlLabel, Grid, Paper, Radio } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useApiError, useAppData, useLoading } from '../../../providers';
import {
  ReportsGeneralToolbar,
  ReportTitle,
  ColumnsSelect,
  RadioBtnCheckedIcon,
} from '../../../components';
import {
  DailyReportRequestData,
  MonthlyReportRequestData,
  ReportData,
  DailyEmailReportRequest,
  MonthlyEmailReportRequest,
  reportEnColumns,
} from './TradingSummarytModel';
import {
  getTradingDailyReport,
  getTradingMonthlyReport,
  sendEmailTradingDailySummary,
  sendEmailTradingMonthlySummary,
} from './TradingSummaryService';
import { MobileTradingReports, TradingReports } from './components';
import { useStyles } from './styles';
import { getSelectedColumnsInEn } from '../../../utils';

export const TradingSummary: FC = () => {
  const currentDate = useMemo(() => new Date(), []);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { userData, translations, locale } = useAppData();
  const { addError } = useApiError();
  const { setIsLoading } = useLoading();
  const [fromDate, setFromDate] = useState<Date>(
    new Date(
      `${
        currentDate.getMonth() + 1
      } 1 ${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`,
    ),
  );
  const [toDate, setToDate] = useState<Date>(currentDate);
  const [reportsData, setReportsData] = useState<ReportData[]>([]);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [isDaily, setIsDaily] = useState<boolean>(false);
  const [translation, setTranslation] = useState<any>([]);
  const columnNames = useMemo(
    () => [
      translation.text_6571,
      translation.text_6572,
      translation.text_6573,
      translation.text_6574,
      translation.text_6575,
      translation.text_6576,
      translation.text_6577,
      translation.text_6578,
      translation.text_6579,
      translation.text_6580,
      translation.text_6581,
    ],
    [translation],
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnNames);
  const [language, setLanguage] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();
  const [accountGuidAuth, setAccountGuidAuth] = useState<string | undefined>();

  useEffect(() => {
    if (columnNames.length === 11) {
      setSelectedColumns(columnNames.slice(0, 8));
    }
  }, [columnNames]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const user = JSON.parse(userData);
    setLanguage(locale);
    setUserEmail(user.userEmail);
    setAccountGuidAuth(user?.accounts[0].accountGuid || '');
  }, [userData, locale]);

  const handleNewReportRequest = useCallback(
    (daily: boolean) => {
      setIsLoading(true);
      // const { accountGuid } = JSON.parse(userData).accounts[0];
      if (daily) {
        const data: DailyReportRequestData = {
          email: '',
          partnershipCode: '',
          brokerDomainId: '',
          dayFrom: fromDate.getDate(),
          dayTo: toDate.getDate(),
          monthFrom: fromDate.getMonth() + 1,
          yearFrom: fromDate.getFullYear(),
          monthTo: toDate.getMonth() + 1,
          yearTo: toDate.getFullYear(),
          filterPlatformType: 1,
          accountTypeFilter: 1,
        };

        getTradingDailyReport(accountGuidAuth, data)
          .then((response) => {
            setReportsData(response.data);
            setShowReport(true);
          })
          .catch((error) => {
            addError(JSON.stringify(error.response));
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        const data: MonthlyReportRequestData = {
          email: '',
          partnershipCode: '',
          brokerDomainId: '',
          monthFrom: fromDate.getMonth() + 1,
          yearFrom: fromDate.getFullYear(),
          monthTo: toDate.getMonth() + 1,
          yearTo: toDate.getFullYear(),
          filterPlatformType: 1,
          accountTypeFilter: 1,
        };

        getTradingMonthlyReport(accountGuidAuth, data)
          .then((response) => {
            setReportsData(response.data);
            setShowReport(true);
          })
          .catch((error) => {
            addError(JSON.stringify(error.response));
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [accountGuidAuth, fromDate, toDate, addError, setIsLoading],
  );

  const changeFromDate = useCallback((newValue) => {
    setFromDate(newValue);
    setShowReport(false);
  }, []);

  const changeToDate = useCallback((newValue) => {
    setToDate(newValue);
    setShowReport(false);
  }, []);

  const handleDailyChange = () => {
    setIsDaily(true);
    handleNewReportRequest(true);
  };

  const handleMonthlyChange = () => {
    setIsDaily(false);
    handleNewReportRequest(false);
  };

  const renderTableData = (): string[][] => {
    const rows: string[][] = [];
    reportsData.forEach((row) => {
      const tableRow: string[] = [
        row.date ? moment(row.date).format('DD/MM/YYYY') : '-',
        row.trades ? row.trades.toFixed(2) : '-',
        row.tradingAccountsTraded ? row.tradingAccountsTraded.toFixed(2) : '-',
        row.depositsUSD ? Number(row.depositsUSD).toFixed(2) : '-',
        row.withdrawalsUSD
          ? Math.abs(Number(row.withdrawalsUSD)).toFixed(2)
          : '-',
        row.valueUSD ? Number(row.valueUSD).toFixed(2) : '-',
        row.lots ? row.lots.toFixed(2) : '-',
        row.volumeLots ? row.volumeLots.toFixed(2) : '-',
        row.emails ? row.emails : '-',
        row.tradingAccountsFunded || row.tradingAccountsFunded === 0
          ? row.tradingAccountsFunded.toString()
          : '-',
        row.netDepositsUSD ? Number(row.netDepositsUSD).toFixed(2) : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  };

  const handleSelectChange = (value: string[]) => {
    setSelectedColumns(value);
  };

  const buildDailyEmailReportRequest = (): DailyEmailReportRequest => ({
    registrationsDailyFilters: {
      email: null,
      brokerFID: 0,
      partnershipCode: '',
      yearFrom: fromDate.getFullYear(),
      yearTo: toDate.getFullYear(),
      monthFrom: fromDate.getMonth() + 1,
      monthTo: toDate.getMonth() + 1,
      filterPlatformType: 1,
      filterAccountType: 1,
      dayFrom: fromDate.getDate(),
      dayTo: toDate.getDate(),
    },
    reportEmailRequest: {
      email: null,
      includeTotal: true,
      tableColumns: getSelectedColumnsInEn(
        reportEnColumns,
        selectedColumns,
        columnNames,
      ),
      language,
      startDate: null,
      endDate: null,
    },
    accountGuidAuth,
  });

  const buildMonthlyEmailReportRequest = (): MonthlyEmailReportRequest => ({
    registrationsMonthlyFilters: {
      email: null,
      brokerFID: 0,
      partnershipCode: '',
      yearFrom: fromDate.getFullYear(),
      yearTo: toDate.getFullYear(),
      monthFrom: fromDate.getMonth() + 1,
      monthTo: toDate.getMonth() + 1,
      filterPlatformType: 1,
      filterAccountType: 1,
    },
    reportEmailRequest: {
      email: null,
      includeTotal: true,
      tableColumns: getSelectedColumnsInEn(
        reportEnColumns,
        selectedColumns,
        columnNames,
      ),
      language,
      startDate: null,
      endDate: null,
    },
    accountGuidAuth,
  });

  const sendEmail = async () => {
    if (isDaily) {
      const dailyEmailReportRequest = buildDailyEmailReportRequest();
      try {
        await sendEmailTradingDailySummary(dailyEmailReportRequest);
        enqueueSnackbar(`${translation.text_1640} ${userEmail}.`, {
          variant: 'success',
        });
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const monthlyEmailReportRequest = buildMonthlyEmailReportRequest();
        await sendEmailTradingMonthlySummary(monthlyEmailReportRequest);
        enqueueSnackbar(`${translation.text_1640} ${userEmail}.`, {
          variant: 'success',
        });
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Grid container direction="column" className={classes.root}>
      {/* <Spinner isLoading={loading} /> */}
      <ReportTitle
        title={translation.text_6411}
        exportFileName={translation.text_6411}
        reportColumns={columnNames}
        exportTableData={renderTableData()}
        printColumnNumber={selectedColumns.length}
        content={
          <TradingReports
            key={Math.random()}
            translation={translation}
            rows={reportsData}
            selectedColumns={selectedColumns}
            reportColumns={columnNames}
          />
        }
        enableButtons={showReport}
        translation={translation}
        sendEmail={sendEmail}
      />
      <Paper className={classes.paper}>
        <Grid container direction="column" className={classes.root}>
          <Grid container direction="row" className={classes.desktop}>
            <Grid item xs={10}>
              <ReportsGeneralToolbar
                fromDate={fromDate}
                toDate={toDate}
                handleFromDateChange={changeFromDate}
                handleToDateChange={changeToDate}
                showButton
                handleButtonClick={() => handleNewReportRequest(isDaily)}
              />
            </Grid>
            <Grid item xs={2}>
              {showReport && (
                <ColumnsSelect
                  reportColumns={columnNames}
                  selectedColumns={selectedColumns}
                  handleSelectColumns={handleSelectChange}
                />
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.mobile}>
            <ReportsGeneralToolbar
              fromDate={fromDate}
              toDate={toDate}
              handleFromDateChange={changeFromDate}
              handleToDateChange={changeToDate}
              showButton
              handleButtonClick={() => handleNewReportRequest(isDaily)}
            />
          </Grid>

          {showReport ? (
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                alignContent="center"
                className={classes.radioButtonContainer}
              >
                <Grid item xs={6} className={classes.mobile}>
                  <ColumnsSelect
                    reportColumns={columnNames}
                    selectedColumns={selectedColumns}
                    handleSelectColumns={handleSelectChange}
                  />
                </Grid>
                <Grid item xs={6} className={classes.radioButtons}>
                  <FormControlLabel
                    label={translation.text_6421}
                    className={`${classes.dailyLabel} ${classes.dailyInput}`}
                    control={
                      <Radio
                        checked={isDaily}
                        checkedIcon={<RadioBtnCheckedIcon />}
                        onChange={handleDailyChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label={translation.text_6422}
                    className={`${classes.dailyLabel}`}
                    control={
                      <Radio
                        checked={!isDaily}
                        checkedIcon={<RadioBtnCheckedIcon />}
                        onChange={handleMonthlyChange}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid item className={classes.desktop}>
                <TradingReports
                  key={Math.random()}
                  translation={translation}
                  rows={reportsData}
                  selectedColumns={selectedColumns}
                  reportColumns={columnNames}
                />
              </Grid>
              <Grid item className={classes.mobile}>
                <MobileTradingReports
                  key={Math.random()}
                  rows={reportsData}
                  selectedColumns={selectedColumns}
                  reportColumns={columnNames}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.emptyReportMessageContainer}
              >
                <Grid item className={classes.groupedByCell}>
                  {translation.text_6423}
                </Grid>
                <Grid item>{translation.text_6424}</Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};
