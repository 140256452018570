import React, { FC, useCallback, useEffect, useState } from 'react';
import { MobileTableView } from '../../../../../components';
import { ValueLabel } from '../../../../../models';
import { PositionsReport } from '../../OpenClosedPositionsSummaryModel';
import { useStyles } from './styles';
import { useAppData } from '../../../../../providers';

interface Props {
  columnNames: string[];
  selectedColumns: string[];
  rows: PositionsReport[];
}

export const MobileReportTable: FC<Props> = ({
  selectedColumns,
  rows,
  columnNames,
}) => {
  const classes = useStyles();
  const { translations } = useAppData();
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const renderRows = useCallback((): any[] => {
    const result: any[] = [];
    const openPositions = translation.text_1219;
    const closedPositions = translation.text_1220;

    if (rows.length === 0) {
      const rowData: ValueLabel[] = [];
      columnNames.forEach((columnName) => {
        if (columnName !== undefined) {
          const isSelected = selectedColumns.includes(columnName);
          if (isSelected) {
            if (
              columnName.indexOf(openPositions) !== -1 ||
              columnName.indexOf(closedPositions) !== -1
            ) {
              if (columnName.indexOf(openPositions) !== -1) {
                const nameArr = columnName.split(openPositions);
                rowData.push({
                  value: '',
                  label: (
                    <>
                      {nameArr[0]} <br />
                      <span className={classes.subColumnName}>
                        {openPositions}
                      </span>
                    </>
                  ),
                });
              } else {
                const nameArr = columnName.split(closedPositions);
                rowData.push({
                  value: '',
                  label: (
                    <>
                      {nameArr[0]} <br />
                      <span className={classes.subColumnName}>
                        {openPositions}
                      </span>
                    </>
                  ),
                });
              }
            } else {
              rowData.push({
                value: '',
                label: columnName,
              });
            }
          }
        }
      });
      result.push(rowData);
    }

    rows.forEach((row) => {
      const rowData: ValueLabel[] = [];

      if (selectedColumns.includes(columnNames[0])) {
        rowData.push({
          value:
            row.partnershipCode || row.partnershipCode === ''
              ? row.partnershipCode
              : '-',
          label: columnNames[0],
        });
      }
      if (selectedColumns.includes(columnNames[1])) {
        rowData.push({
          value: row.login || row.login === 0 ? row.login : '-',
          label: columnNames[1],
        });
      }
      if (selectedColumns.includes(columnNames[2])) {
        const nameArr = columnNames[2].split(openPositions);
        rowData.push({
          value:
            row.openedVolumeUSD || row.openedVolumeUSD === 0
              ? row.openedVolumeUSD.toFixed(2)
              : '-',
          label: (
            <>
              {nameArr[0]} <br />
              <span className={classes.subColumnName}>{openPositions}</span>
            </>
          ),
        });
      }
      if (selectedColumns.includes(columnNames[3])) {
        const nameArr = columnNames[3].split(openPositions);
        rowData.push({
          value:
            row.openLots || row.openLots === 0 ? row.openLots.toFixed(2) : '-',
          label: (
            <>
              {nameArr[0]} <br />
              <span className={classes.subColumnName}>{openPositions}</span>
            </>
          ),
        });
      }
      if (selectedColumns.includes(columnNames[4])) {
        const nameArr = columnNames[4].split(openPositions);
        rowData.push({
          value:
            row.openTrades || row.openTrades === 0
              ? row.openTrades.toFixed(2)
              : '-',
          label: (
            <>
              {nameArr[0]} <br />
              <span className={classes.subColumnName}>{openPositions}</span>
            </>
          ),
        });
      }
      if (selectedColumns.includes(columnNames[5])) {
        const nameArr = columnNames[5].split(openPositions);
        rowData.push({
          value:
            row.openPnL || row.openPnL === 0 ? row.openPnL.toFixed(2) : '-',
          label: (
            <>
              {nameArr[0]} <br />
              <span className={classes.subColumnName}>{openPositions}</span>
            </>
          ),
        });
      }
      if (selectedColumns.includes(columnNames[6])) {
        const nameArr = columnNames[6].split(closedPositions);
        rowData.push({
          value:
            row.closedVolumeUSD || row.closedVolumeUSD === 0
              ? row.closedVolumeUSD.toFixed(2)
              : '-',
          label: (
            <>
              {nameArr[0]} <br />
              <span className={classes.subColumnName}>{closedPositions}</span>
            </>
          ),
        });
      }
      if (selectedColumns.includes(columnNames[7])) {
        const nameArr = columnNames[7].split(closedPositions);
        rowData.push({
          value:
            row.closedLots || row.closedLots === 0
              ? row.closedLots.toFixed(2)
              : '-',
          label: (
            <>
              {nameArr[0]} <br />
              <span className={classes.subColumnName}>{closedPositions}</span>
            </>
          ),
        });
      }
      if (selectedColumns.includes(columnNames[8])) {
        const nameArr = columnNames[8].split(closedPositions);
        rowData.push({
          value:
            row.closedTrades || row.closedTrades === 0
              ? row.closedTrades.toFixed(2)
              : '-',
          label: (
            <>
              {nameArr[0]} <br />
              <span className={classes.subColumnName}>{closedPositions}</span>
            </>
          ),
        });
      }
      if (selectedColumns.includes(columnNames[9])) {
        const nameArr = columnNames[9].split(closedPositions);
        rowData.push({
          value:
            row.closedPnL || row.closedPnL === 0
              ? row.closedPnL.toFixed(2)
              : '-',
          label: (
            <>
              {nameArr[0]} <br />
              <span className={classes.subColumnName}>{closedPositions}</span>
            </>
          ),
        });
      }
      result.push(rowData);
    });

    return result;
  }, [selectedColumns, rows, classes, columnNames, translation]);

  return (
    <MobileTableView
      list={renderRows()}
      grouped={false}
      hasNextButton={false}
    />
  );
};
