export interface ClientListItem {
  accountType: string;
  clientCountry: string;
  clientLogin: number;
  clientName: string;
  closedPositions: number;
  currency: string;
  deposits: string;
  lastTradeDate: Date;
  leverage: number;
  netDeposits: string;
  partnershipEndDate: Date;
  partnershipCode: string;
  partnershipStartDate: Date;
  phone: string;
  realEquity: string;
  volume: string;
  volumeLots: string;
  withDrawals: string;
  netInternalTransfersIn: string;
  netInternalTransfersOut: string;
}

export interface AdminClientListRequest {
  partnerEmail: string;
  fromDate: string;
  toDate: string;
  partnershipCodes: string[];
  currencies: string[];
  showInactive: boolean;
}

export const reportEnColumns = [
  'partnership',
  'client name',
  'phone',
  'country',
  'currency',
  'account type',
  'start date',
  'login',
  'end date',
  'last trade date',
  'leverage',
  'volume',
  'volume lots',
  'closed positions',
  'deposits',
  'withdrawals',
  'net deposits',
  'real equity',
];
export interface ClientListEmailReportRequest {
  partnersClientsFilters: {
    currencies: string[];
    partnershipIds: number[];
  };
  reportEmailRequest: {
    email: null | string;
    includeTotal: boolean;
    tableColumns: string[];
    language: string | undefined;
    startDate: string | null;
    endDate: string | null;
  };
  showInactive: boolean;
}
