import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { Grid, IconButton, TableCell, TableRow } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { Partner, ReportDetailsModel, ReportModel } from 'src/models';
import { useStyles } from '../../styles';
import { useCommonStyles } from '../../../../../styles';
import { useAppData, useLoading } from '../../../../../providers';
import { CustomTable } from '../../../../../components';
import { CommissionDialog } from './components';
import useGetSummaryByClient from '../../hooks/useGetSummaryByClient';

interface Props {
  toDate: Date;
  fromDate: Date;
  date: string;
  dateFrom: Date;
  partnerId: Partner['id'];
  columnNames: string[];
  selectedColumns: string[];
  rows: ReportModel[];
}

export const PartnershipReports: FC<Props> = ({
  date,
  toDate,
  fromDate,
  columnNames,
  selectedColumns,
  rows,
  dateFrom,
}) => {
  const timestamp = new Date().getTime();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { translations } = useAppData();
  const { setIsLoading } = useLoading();
  const [dialogTrigger, setDialogTrigger] =
    useState<number | undefined>(undefined);
  const [summaryData, setSummaryData] = useState<ReportDetailsModel[]>([]);
  const [translation, setTranslation] = useState<any>([]);
  const [selectedPartnership, setSelectedPartnership] = useState<string>('');

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  const renderHeader = useMemo((): any[] => {
    const result: any[] = [];
    columnNames.forEach((columnName) => {
      const isSelected = selectedColumns.includes(columnName);
      if (isSelected) {
        result.push(
          <TableCell
            key={columnName}
            align={columnName === columnNames[0] ? 'left' : 'center'}
          >
            {columnName}
          </TableCell>,
        );
      } else if (columnName === columnNames[0]) {
        result.push(<TableCell key={`first_${timestamp}`} />);
      }
    });
    result.push(<TableCell key={`last_${timestamp}`} />);
    return result;
  }, [columnNames, selectedColumns, timestamp]);

  const renderTotalRow = useMemo((): any => {
    let rebateLocalSum = 0;
    let paymentSum = 0;
    let totalRebatesSum = 0;
    let profitSum = 0;
    let subIbCommissionSum = 0;

    rows.forEach((row) => {
      rebateLocalSum += row.reportTotal.rebateUSD
        ? row.reportTotal.rebateUSD
        : 0;
      paymentSum += row.reportTotal.paymentUSD ? row.reportTotal.paymentUSD : 0;
      totalRebatesSum += row.totalRebatesUSD ? row.totalRebatesUSD : 0;
      profitSum += row.reportTotal.profitUSD ? row.reportTotal.profitUSD : 0;
      subIbCommissionSum += row.subPartnershipsRebateSum
        ? row.subPartnershipsRebateSum
        : 0;
    });

    return (
      <TableRow className={classes.tableTotals} key={`totals_${timestamp}`}>
        <TableCell align="right">{translation.text_951}</TableCell>
        {selectedColumns.includes(columnNames[1]) && (
          <TableCell align="center">{`$${rebateLocalSum.toFixed(
            2,
          )}`}</TableCell>
        )}
        {selectedColumns.includes(columnNames[2]) && (
          <TableCell align="center">{`$${totalRebatesSum.toFixed(
            2,
          )}`}</TableCell>
        )}
        {selectedColumns.includes(columnNames[3]) && (
          <TableCell align="center">{`$${paymentSum.toFixed(2)}`}</TableCell>
        )}
        {selectedColumns.includes(columnNames[4]) && (
          <TableCell align="center">{`$${profitSum.toFixed(2)}`}</TableCell>
        )}
        {selectedColumns.includes(columnNames[5]) && (
          <TableCell align="center" />
        )}
        {selectedColumns.includes(columnNames[6]) && (
          <TableCell align="center" />
        )}
        {selectedColumns.includes(columnNames[7]) && (
          <TableCell align="center" />
        )}
        {selectedColumns.includes(columnNames[8]) && (
          <TableCell align="center">{`$${subIbCommissionSum.toFixed(
            2,
          )}`}</TableCell>
        )}
        <TableCell align="center" />
      </TableRow>
    );
  }, [rows, columnNames, selectedColumns, classes, timestamp]);

  const getSummaryData = useGetSummaryByClient({
    toDate,
    fromDate,
    setSummaryData,
    setIsLoading,
  });

  const openDetailsDialog = useCallback(
    async (partnershipCode: string) => {
      await getSummaryData(partnershipCode);
      setDialogTrigger(Math.random());
      setSelectedPartnership(partnershipCode);
    },
    [getSummaryData],
  );

  const renderRows = useMemo((): any[] => {
    const result: any[] = [];

    rows.forEach((row) => {
      result.push(
        <TableRow className={commonClasses.tableRow} key={row.id}>
          {selectedColumns.includes(columnNames[0]) ? (
            <TableCell align="left">
              {row.partnershipCode ? row.partnershipCode : '-'}
            </TableCell>
          ) : (
            <TableCell />
          )}
          {selectedColumns.includes(columnNames[1]) && (
            <TableCell align="center">
              {row.reportTotal.rebateUSD || row.reportTotal.rebateUSD === 0
                ? `$${row.reportTotal.rebateUSD.toFixed(2)}`
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[2]) && (
            <TableCell align="center">
              {row.totalRebatesUSD || row.totalRebatesUSD === 0
                ? `$${row.totalRebatesUSD.toFixed(2)}`
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[3]) && (
            <TableCell align="center">
              {row.paymentUSD || row.paymentUSD === 0
                ? `$${row.paymentUSD.toFixed(2)}`
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[4]) && (
            <TableCell align="center">
              {row.reportTotal.profitUSD || row.reportTotal.profitUSD === 0
                ? `$${row.reportTotal.profitUSD.toFixed(2)}`
                : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[5]) && (
            <TableCell align="center">
              {row.partnershipType ? row.partnershipType : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[6]) && (
            <TableCell align="center">
              {row.schemeName ? row.schemeName : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[7]) && (
            <TableCell align="center">
              {row.schemeType ? row.schemeType : '-'}
            </TableCell>
          )}
          {selectedColumns.includes(columnNames[8]) && (
            <TableCell align="center">
              {row.subPartnershipsRebateSum ||
              row.subPartnershipsRebateSum === 0
                ? `$${row.subPartnershipsRebateSum.toFixed(2)}`
                : '-'}
            </TableCell>
          )}
          <TableCell align="center">
            <IconButton onClick={() => openDetailsDialog(row.partnershipCode)}>
              <ArrowForwardIos className={classes.arrowIcon} fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>,
      );
    });

    return result;
  }, [
    rows,
    columnNames,
    selectedColumns,
    openDetailsDialog,
    classes,
    commonClasses,
  ]);

  const renderTitle = useCallback(
    (): any => <Grid style={{ fontWeight: 'normal' }}>{date}</Grid>,
    [date],
  );

  return (
    <>
      <CustomTable
        headerCells={renderHeader}
        rows={renderRows}
        totalRow={renderTotalRow}
        columnsNumber={selectedColumns.length}
        emptyDataMessage={translation.text_6448}
      />
      <CommissionDialog
        toDate={toDate}
        fromDate={fromDate}
        partnershipCode={selectedPartnership}
        key={`com_dialog${timestamp}`}
        title={translation.text_6484}
        subTitle={renderTitle()}
        dateFrom={dateFrom}
        data={summaryData}
        trigger={dialogTrigger}
        translation={translation}
        handleCancel={() => setDialogTrigger(undefined)}
      />
    </>
  );
};
