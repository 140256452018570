/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useStyles } from '../styles';
import { GraphWithPeriod, PanelInsights, PanelTitle } from '../../../../components';
import { useCommonStyles } from '../../../../styles';
import { useApiError, useLoading } from '../../../../providers';
import { getDashboardInsights, getHighlightData } from '../../IBDashboardService';
import { HighlightTypes } from '../../IBDashboardModels';
import { PATH_ALL_CLIENTS_AND_TRADING, PATH_CLIENTS_LIST } from '../../../../utils';

interface Props {
  partnership: string;
  value: number;
  translation: any;
}

export const ClientsPanel: FC<Props> = ({ value, partnership, translation }) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonClasses = useCommonStyles();
  const { setIsLoading } = useLoading();
  const currentDate = useMemo(() => new Date(), []);
  const [fromDate, setFromDate] = useState<Date>(moment().startOf('year').toDate());
  const [toDate, setToDate] = useState<Date>(currentDate);
  const { addError } = useApiError();
  const [data, setData] = useState<any>();
  const [insightsData, setInsightsData] = useState<any>();
  const [increased, setIncreased] = useState<number | undefined>();
  const [decreased, setDecreased] = useState<number | undefined>();

  const getHighLightData = useCallback(
    async (partnershipCode: string, from: Date, to: Date) => {
      setIsLoading(true);
      try {
        const response = await getHighlightData(from, to, partnershipCode === 'All' ? '' : partnershipCode, HighlightTypes.Clients);
        setData(response.data);

        const { monthData } = response.data;
        monthData.forEach((item: any) => {
          const arr = item.period.split('-');
          let itemIncreased = 0;
          let itemDecreased = 0;

          if (item.increased < 0) {
            itemDecreased = Math.abs(item.increased);
          } else {
            itemIncreased = item.increased;
          }

          if (currentDate.getFullYear().toString() === arr[0] && (currentDate.getMonth() + 1).toString() === arr[1]) {
            setIncreased(itemIncreased);
            setDecreased(itemDecreased);
          }
        });
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [addError, currentDate],
  );

  const getInsightData = useCallback(
    async (translations, partnershipCode: string, from: Date, to: Date) => {
      setIsLoading(true);
      try {
        const response = await getDashboardInsights(from, to, partnershipCode === 'All' ? '' : partnershipCode, HighlightTypes.Clients);
        const insights = response.data.partnerInsightViewModel;

        const insightsValues: any = [
          {
            name: translations.text_6320,
            value: insights.newClientsCount,
          },
          {
            name: translations.text_6321,
            value: insights.topClientCountries !== null && insights.topClientCountries !== '' ? insights.topClientCountries : '-',
          },
          {
            name: translations.text_6322,
            value: insights.topClientCurrencies !== null && insights.topClientCurrencies !== '' ? insights.topClientCurrencies : '-',
          },
        ];

        setInsightsData(insightsValues);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [addError],
  );

  useEffect(() => {
    if (fromDate <= toDate) {
      (async () => {
        await getHighLightData(partnership, fromDate, toDate);
        await getInsightData(translation, partnership, fromDate, toDate);
      })();
    }
  }, [partnership, fromDate, toDate, translation]);

  return (
    <>
      <PanelTitle translation={translation} title={translation.text_6319} value={value.toString()} increased={increased} decreased={decreased} fromDate={fromDate} toDate={toDate} handleFromDateChange={setFromDate} handleToDateChange={setToDate} />
      <Grid container direction="column" alignContent="center" className={classes.content}>
        {data || insightsData ? (
          <>
            {data && (
              <Grid container direction="row" className={classes.content} justify="space-between">
                <GraphWithPeriod data={data} colors={[theme.palette.lineGraph.first]} translation={translation} />
              </Grid>
            )}
            {insightsData && (
              <Grid item xs={12}>
                <PanelInsights translation={translation} title={`${translation.text_6324} ${partnership === 'All' ? `${translation.text_6325}` : `${translation.text_6326} ${partnership}`}`} items={insightsData} />
              </Grid>
            )}
          </>
        ) : (
          <Grid container direction="column" alignContent="center" alignItems="center" className={classes.emptyMessage}>
            <Grid item>{translation.text_6323}</Grid>
          </Grid>
        )}
        <Grid container className={classes.buttons}>
          <Grid item className={classes.firstButton}>
            <Button variant="contained" color="primary" className={`${commonClasses.linkButtons} ${classes.button}`} component={Link} to={PATH_CLIENTS_LIST}>
              {translation.text_6327}
            </Button>
          </Grid>
          <Grid item className={classes.secondButton}>
            <Button variant="contained" color="primary" className={`${commonClasses.linkButtons} ${classes.button}`} component={Link} to={PATH_ALL_CLIENTS_AND_TRADING}>
              {translation.text_6328}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
