const variables = {
  // new colors
  main: '#4D629E',
  active: '#50d6b2',
  cobaltBlue: '#869FE8',
  font: '#696E7E',
  lightGrey: '#E0E2E5',
  pearlGrey: '#f1f2f7',
  grey: '#C6C9D2',
  background: '#F1F2F2',
  cadetBlue: '#A9ADB9',
  cultured: '#F4F4F4',
  ghostWhite: '#F9F9F9',
  blackOlive: '#3E3E3E',
  wildBlue: '#A7AECA',
  lightWildBlue: '#939BB5',
  gunMetal: '#262A3C',
  green: '#00CC99',
  blue: '#37CEFF',
  maxBlue: '#49b7d2',
  pastelPink: '#E09676',
  white: '#fefefe',
  black: '#222222',
  red: '#FA2742',
  darkBlue: '#3A4163',
  nickel: '#696E7E',
  boxShadow: '#677eac29',
  slateGrey: '#788290',
  davyGrey: '#5a5a5a',
  abalonGrey: '#e4e6ed',
  fossilGrey: '#505050',
  chineseWhite: '#e1e1e1',
  buttonGrey: '#f3f3f3',
  lineGraphBlue: '#49B7D2',
  lineGraphOrange: '#EECF8F',
  lineGraphGreen: '#7CD9C2',
  lineGraphText: '#818181',
  lineGraphTooltipText: '#565656',
  lineGraphTooltipBlue: '#a3dbe8',
  lineGraphTooltipOrange: '#f6e8c9',
  lineGraphTooltipGreen: '#bfede1',
  lineGraphTooltipRed: '#ee8f8f',
  lightWater: '#E2F8FF',
  water: '#D7E9FF',
  error: '#FF3730',
  greenButton: '#6CC4A4',
  greenHoverButton: '#4FA787',
  lightGreenButton: '#cceadf',
  selectorOptionGrey: '#F8F8F8',
  selectorGrey: '#B8BEC6',
  darkPastelRed: '#b74924',
  pinkLace: '#fee0fd',
  aeroBlue: '#b7ffe580',
  platinum: '#eee6e5',
  flax: '#eecf8f99',
  cornflowerBlue: '#b6c9fe',
  dimGrey: '#696969',
  weldonBlue: '#759fa5',
  steelTeal: '#638a8f',
  carminePink: '#e17069',
  darkGrey: '#343c45',
  lightLinkGrey: '#757575',
  logOutButtonBlue: '#0075FF',
  reportMenuButton: '#E2EDF8',
  reportMenuButtonCircle: '#203c364d',
  violet: '#efecff',
  lightSilver: '#d9d9d9',
  glaucous: '#5D74B6',
  lavander: '#E5ECF6',
  babyBlue: '#99C8FF',
  blueYounder: '#A6AECA',
  // color alpha https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
  alpha: {
    twelve: '1f',
    fifteen: '26',
    twenty: '33',
    thirty: '4d',
    fifty: '80',
    seventy: 'B3',
    eighty: 'cc',
  },

  // breakpoints
  xl: 1920,
  lg: 1280,
  md: 960,
  sm: 600,
  xs: 0,

  // spacing
  spacing: 6,

  // left menu
  leftMenuWidth: 222,

  // border
  borderWidth: 1,
  borderRadius: 10,

  // dialog
  dialogMinWidth: 600,
};

export default variables;
