import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { getTranslation } from 'src/utils';
import { useAppData, useLoading } from '../../../providers';
import {
  ReportsGeneralToolbar,
  ReportTitle,
  ColumnsSelect,
} from '../../../components';
import { useStyles } from './styles';
import {
  MobileCommissionsDetailedTable,
  PartnershipReports,
} from './components';
import { MobilePartnershipReports } from '../CommissionsByClient/components';
import useSendEmail from './hooks/useSendEmail';
import useGetReports from './hooks/useGetReports';
import useGetSummaryByClient from './hooks/useGetSummaryByClient';
import { ReportModel } from '../../../models';

export const AllClientsAndTrading: FC = () => {
  const currentDate = useMemo(() => new Date(), []);
  const classes = useStyles();
  const { userData, locale, translations } = useAppData();
  const { setIsLoading } = useLoading();
  const [fromDate, setFromDate] = useState<Date>(
    new Date(
      `${
        currentDate.getMonth() + 1
      } 1 ${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`,
    ),
  );
  const [toDate, setToDate] = useState<Date>(currentDate);
  const [reports, setReports] = useState<ReportModel[]>([]);
  const [showReports, setShowReports] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<any>(null);
  const [summaryData, setSummaryData] = useState<any>(undefined);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [translation, setTranslation] = useState<any>([]);
  const columnNames = useMemo(
    () => [
      translation.text_6539,
      translation.text_6540,
      translation.text_6542,
      translation.text_6543,
      translation.text_6544,
      translation.text_6545,
      translation.text_6546,
      translation.text_6547,
      translation.text_6548,
    ],
    [translation],
  );
  const [selected, setSelected] = useState<string[]>(columnNames);
  const [language, setLanguage] = useState<string>();

  const dateRange = useMemo(
    () =>
      `
      ${getTranslation(translation, moment(fromDate).format('MMMM'))}  ${moment(
        fromDate,
      ).format('YYYY')} - 
      ${getTranslation(translation, moment(toDate).format('MMMM'))}  ${moment(
        toDate,
      ).format('YYYY')}      
      `,
    [fromDate, toDate, translation],
  );

  useEffect(() => {
    if (columnNames) {
      setSelected(columnNames);
    }
  }, [columnNames]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const userLoginData = JSON.parse(userData);
    setLoginData(userLoginData);
    setLanguage(locale);
  }, [userData, locale]);

  useEffect(() => {
    if (columnNames.length === 9) {
      setSelected(columnNames.slice(0, 6));
    }
  }, [columnNames]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1024) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
        setSummaryData(undefined);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
      setSummaryData(undefined);
    }
  }, []);

  const handleNewReportRequest = useGetReports({
    translation,
    toDate,
    fromDate,
    setReports,
    showReports,
    setShowReports,
    setIsLoading,
  });

  const changeFromDate = useCallback((newValue) => {
    setFromDate(newValue);
  }, []);

  const changeToDate = useCallback((newValue) => {
    setToDate(newValue);
  }, []);

  const renderTableData = (): string[][] => {
    const rows: string[][] = [];
    reports.forEach((row) => {
      const tableRow: string[] = [
        row.partnershipCode ? row.partnershipCode : '-',
        row.reportTotal.rebateUSD || row.reportTotal.rebateUSD === 0
          ? row.reportTotal.rebateUSD.toFixed(2)
          : '-',
        row.totalRebatesUSD || row.totalRebatesUSD === 0
          ? row.totalRebatesUSD.toFixed(2)
          : '-',
        row.paymentUSD || row.paymentUSD === 0
          ? row.paymentUSD.toFixed(2)
          : '-',
        row.reportTotal.profitUSD || row.reportTotal.profitUSD === 0
          ? row.reportTotal.profitUSD.toFixed(2)
          : '-',
        row.partnershipType ? row.partnershipType : '-',
        row.schemeName ? row.schemeName : '-',
        row.schemeType ? row.schemeType : '-',
        row.subPartnershipsRebateSum || row.subPartnershipsRebateSum === 0
          ? row.subPartnershipsRebateSum.toFixed(2)
          : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  };

  const getSummaryData = useGetSummaryByClient({
    toDate,
    fromDate,
    setSummaryData,
    setIsLoading,
  });

  const handleNextButtonClick = useCallback(
    async (row: any) => {
      await getSummaryData(row.id);
    },
    [getSummaryData],
  );

  const sendEmail = useSendEmail({
    toDate,
    fromDate,
    selected,
    reportColumns: columnNames,
    selectedLanguage: language,
  });

  return (
    <>
      {isMobileView && summaryData !== undefined ? (
        <MobileCommissionsDetailedTable
          title={translation.text_6484}
          dateFrom={fromDate}
          data={summaryData}
          onBackClick={() => setSummaryData(undefined)}
        />
      ) : (
        <Grid container direction="column" className={classes.root}>
          <ReportTitle
            title={translation.text_6484}
            exportFileName={translation.text_6486}
            reportColumns={columnNames}
            exportTableData={renderTableData()}
            printColumnNumber={selected.length}
            content={
              <PartnershipReports
                fromDate={fromDate}
                toDate={toDate}
                partnerId={loginData?.partnerID}
                date={dateRange}
                dateFrom={fromDate}
                columnNames={columnNames}
                selectedColumns={selected}
                rows={reports}
              />
            }
            enableButtons={showReports}
            translation={translation}
            sendEmail={sendEmail}
          />
          <Paper className={classes.paper}>
            <Grid container direction="column" className={classes.root}>
              <Grid container direction="row" className={classes.desktop}>
                <Grid item xs={10}>
                  <ReportsGeneralToolbar
                    fromDate={fromDate}
                    toDate={toDate}
                    handleFromDateChange={changeFromDate}
                    handleToDateChange={changeToDate}
                    showButton
                    handleButtonClick={handleNewReportRequest}
                  />
                </Grid>
                <Grid item xs={2}>
                  {showReports && (
                    <ColumnsSelect
                      reportColumns={columnNames}
                      selectedColumns={selected}
                      handleSelectColumns={setSelected}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={classes.mobile}>
              <ReportsGeneralToolbar
                fromDate={fromDate}
                toDate={toDate}
                handleFromDateChange={changeFromDate}
                handleToDateChange={changeToDate}
                showButton
                handleButtonClick={handleNewReportRequest}
              />
            </Grid>
            {showReports ? (
              <Grid item xs={12}>
                <Grid item xs={6} className={classes.mobile}>
                  <ColumnsSelect
                    reportColumns={columnNames}
                    selectedColumns={selected}
                    handleSelectColumns={setSelected}
                  />
                </Grid>

                <Grid className={classes.reportTable}>
                  <PartnershipReports
                    fromDate={fromDate}
                    toDate={toDate}
                    partnerId={loginData?.partnerID}
                    date={dateRange}
                    dateFrom={fromDate}
                    columnNames={columnNames}
                    selectedColumns={selected}
                    rows={reports}
                  />
                </Grid>
                <Grid className={classes.reportTableMobile}>
                  <MobilePartnershipReports
                    columnNames={columnNames}
                    selectedColumns={selected}
                    rows={reports}
                    onNextClick={handleNextButtonClick}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  className={classes.emptyReportMessageContainer}
                >
                  <Grid item className={classes.groupedByCell}>
                    {translation.text_6488}
                  </Grid>
                  <Grid item>{translation.text_6489}</Grid>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      )}
    </>
  );
};
