import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
    position: 'relative'
  },
  title: {
    textAlign: 'left',
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.frequent.davyGrey
  },
  paper: {
    marginTop: 13,
    padding: 15,
    minHeight: '100vh'
  },
  noRepresentativesMessageContainer: {
    marginTop: 135,
    fontSize: '13pt',
    marginBottom: 20
  },
  boldText: {
    fontWeight: 'bold'
  },
  button: {
    height: 40,
    borderRadius: 20,

    '@media (max-width: 640px)': {
      fontSize: 13,
      fontWeight: 'normal',
    },

    '@media (max-width: 427px)': {
      fontSize: 12,
    }
  },
  radiobutton: {
    fontSize: 17,
    color: theme.palette.primary.main
  },
  label: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    }
  },
  partnershipActionIcon: {
    fontSize: theme.spacing(5.5),
    color: theme.palette.secondary.light,
    fontColor: theme.palette.frequent.grey,
    position: 'relative',
    zIndex: 1,
  },
  disabled: {
    opacity: '0.4',
    pointerEvents: 'none',

    '& $partnershipCard': {
      backgroundColor: theme.palette.secondary.light
    },

    '& svg circle': {
      fill: theme.palette.frequent.lightSilver
    }
  }
}),);
