export interface DailyReportRequestData {
  email: string;
  brokerDomainId?: string;
  partnershipCode: string;
  dayFrom: number;
  dayTo: number;
  yearFrom: number;
  yearTo: number;
  monthFrom: number;
  monthTo: number;
  filterPlatformType: number;
  accountTypeFilter: number;
}

export interface MonthlyReportRequestData {
  email: string;
  brokerDomainId?: string;
  partnershipCode: string;
  yearFrom: number;
  yearTo: number;
  monthFrom: number;
  monthTo: number;
  filterPlatformType: number;
  accountTypeFilter: number;
}

export interface ReportData {
  clientsFunded: number;
  clientsTraded: number;
  date?: string;
  depositsUSD: string;
  lots: number;
  volumeLots: number;
  netDepositsUSD: string;
  trades: number;
  tradingAccountsFunded: number;
  tradingAccountsTraded: number;
  valueUSD: string;
  withdrawalsUSD: string;
  emails: string;
  firstTrade: number;
}

export interface DailyEmailReportRequest {
  registrationsDailyFilters: {
    email: null | string;
    brokerFID: number;
    partnershipCode: string;
    yearFrom: number;
    yearTo: number;
    monthFrom: number;
    monthTo: number;
    filterPlatformType: number;
    filterAccountType: number;
    dayFrom: number;
    dayTo: number;
  };
  reportEmailRequest: {
    email: null | string;
    includeTotal: boolean;
    tableColumns: string[];
    language: string | undefined;
    startDate: string | null;
    endDate: string | null;
  };
  accountGuidAuth: string | undefined;
}

export interface MonthlyEmailReportRequest {
  registrationsMonthlyFilters: {
    email: null | string;
    brokerFID: number;
    partnershipCode: string;
    yearFrom: number;
    yearTo: number;
    monthFrom: number;
    monthTo: number;
    filterPlatformType: number;
    filterAccountType: number;
  };
  reportEmailRequest: {
    email: null | string;
    includeTotal: boolean;
    tableColumns: string[];
    language: string | undefined;
    startDate: string | null;
    endDate: string | null;
  };
  accountGuidAuth: string | undefined;
}

export const reportEnColumns = [
  'date',
  'total trades',
  'users traded',
  'deposits',
  'withdrawals (USD)',
  'value',
  'lots',
  'volume Lots',
  'users traded (email)',
  'funded accounts',
  'net deposits',
];
