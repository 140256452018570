import React, { FC, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import { NotificationItem } from './components';
import { useStyles } from './styles';
import { Notification } from '../../models';
import { useAppData } from '../../providers';

export const NotificationsPage: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { translations } = useAppData();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [translation, setTranslation] = useState<any>([]);

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const props = location.state as Notification[];
    setNotifications(props);
  }, [location]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <Typography className={classes.title}>{translation.text_1618}</Typography>
      </Grid>
      <Grid container direction="column">
        <Grid item xs={12}>
          {notifications.length > 1 ? (
            <Grid container direction="row" justify="space-between">
              <Grid item style={{ width: '49%' }}>
                {notifications.slice(0, Math.ceil(notifications.length / 2))
                  .map((notification: any) => (
                    <NotificationItem key={notification.id} data={notification} />
                  ))}
              </Grid>
              <Grid item style={{ width: '49%' }}>
                {notifications.slice(-Math.ceil((notifications.length - 1) / 2))
                  .map((notification: any) => (
                    <NotificationItem key={notification.id} data={notification} />
                  ))}
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row" justify="space-between">
              <Grid item style={{ width: '49%' }}>
                {notifications.map((notification: any) => (
                  <NotificationItem key={notification.id} data={notification} />
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
