import { TooltipProps as TooltipMuiProps } from '@material-ui/core';
import React, {
  ComponentPropsWithoutRef,
  ElementType,
  ReactNode,
  createElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LightTooltip } from '../LightTooltip';

type BasicTooltipProps<C extends ElementType = 'div'> = Pick<
  TooltipMuiProps,
  'title' | 'interactive' | 'enterTouchDelay'
> & {
  component?: C;
  children?: ReactNode;
};

export type OverflowTipProps<C extends ElementType> = BasicTooltipProps<C> &
  Omit<ComponentPropsWithoutRef<C>, keyof BasicTooltipProps<C>>;

export const OverflowTip = <C extends ElementType>({
  title,
  children,
  interactive,
  enterTouchDelay = 0,
  component = 'div' as C,
  ...rest
}: OverflowTipProps<C>) => {
  const [isOverflown, setIsOverflown] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      setIsOverflown(element.scrollWidth > element.clientWidth);
    }
  }, []);

  return (
    <LightTooltip
      interactive={interactive}
      enterTouchDelay={enterTouchDelay}
      disableHoverListener={!isOverflown}
      title={title}
    >
      {createElement(
        component,
        {
          ref,
          ...rest,
          style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
        children,
      )}
    </LightTooltip>
  );
};
