import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    width: '152px !important',
    display: 'none',
    marginRight: 13,
    height: 40,
    backgroundColor: theme.palette.changeable.exportButtons,
    color: theme.palette.common.white,
    justifyContent: 'center',
    borderRadius: 20,

    '&:hover': {
      backgroundColor: theme.palette.changeable.hover,
      color: theme.palette.common.white,
    },

    '@media (max-width: 915px)': {
      marginLeft: 10,
      marginRight: 10,
      width: '158px !important;',
      height: 29,
      padding: '7px 9px',
    },
    '@media (max-width: 420px)': {
      marginLeft: 5,
      marginRight: 5,
    },
    '@media (max-width: 360px)': {
      width: '146px !important;',
      height: 29,
      padding: '7px 9px',
    },
  },
}));
