import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'flex-start',

      '@media (max-width: 1023px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    dateFieldsLabel: {
      paddingLeft: 24,
    },
    toDate: {
      width: 200,
      '& .MuiOutlinedInput-root': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      '@media (max-width: 640px)': {
        width: 180,

        '& .MuiOutlinedInput-root': {
          fontSize: 14,

          '& .MuiTypography-body1': {
            fontSize: 14,
          },
        },
      },

      '@media (max-width: 400px)': {
        width: 160,

        '& .MuiOutlinedInput-root': {
          fontSize: 12,

          '& .MuiTypography-body1': {
            fontSize: 12,
          },
        },
      },
    },
    toDateError: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderBottomColor: theme.palette.error.main,
        borderTopColor: theme.palette.error.main,
        borderRightColor: theme.palette.error.main,
      },
    },
    fromDate: {
      width: 220,
      '& .MuiOutlinedInput-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },

      '@media (max-width: 643px)': {
        width: 195,

        '& .MuiOutlinedInput-root': {
          fontSize: 14,

          '& .MuiTypography-body1': {
            fontSize: 14,
          },
        },
      },

      '@media (max-width: 400px)': {
        width: 175,

        '& .MuiOutlinedInput-root': {
          fontSize: 12,

          '& .MuiTypography-body1': {
            fontSize: 12,
          },
        },
      },
    },
    fromDateError: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderBottomColor: theme.palette.error.main,
        borderTopColor: theme.palette.error.main,
        borderLeftColor: theme.palette.error.main,
      },
    },
    errorMessage: {
      textAlign: 'left',
      color: theme.palette.error.main,
      paddingTop: 5,
    },
    dateFields: {
      marginRight: 20,

      '@media (max-width: 1023px)': {
        marginRight: 0,
        marginBottom: 24,
      },
    },
    showAll: {
      color: theme.palette.frequent.logOutButtonBlue,
      width: '100%',
      textAlign: 'center',
    },
    button: {
      height: 40,
      padding: '10px 20px 10px 20px',
      justifyContent: 'center',
      borderRadius: 20,
      marginTop: 24,

      '@media (max-width: 1023px)': {
        width: 376,
        borderRadius: 10,
      },

      '@media (max-width: 413px)': {
        width: 310,
        marginTop: 0,
      },

      '&:hover': {
        backgroundColor: theme.palette.changeable.hover,
        color: theme.palette.common.white,
      },
    },
  }),
);
