import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  iconButton: {
    marginLeft: theme.spacing(1.2),
    padding: 0,
  },
  menuIcon: {
    padding: 0,
    '& svg path': {
      fill: theme.palette.primary.main
    },
    '& svg circle': {
      stroke: theme.palette.primary.main
    }
  },
  disabled: {
    padding: 0,
    '& svg path': {
      fill: theme.palette.frequent.lightSilver
    },
    '& svg circle': {
      stroke: theme.palette.frequent.lightSilver
    }
  },
  revokeTitle: {
    fontSize: 12,
    marginLeft: theme.spacing(2)
  },
  reSendTitle: {
    fontSize: 12,
    marginLeft: theme.spacing(2),
  },
  closeTitle: {
    fontSize: 12,
    textAlign: 'center',
    flex: 1,
    padding: 6
  },
  popper: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.frequent.fossilGrey,
    boxShadow: '0 4px 10px 0 rgba(170, 170, 170, 0.25)',
    padding: 0,
    overflow: 'visible',
    borderRadius: 5,
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: -14,
      width: 10,
      height: 10,
      backgroundColor: theme.palette.common.white,
      transform: 'translate(100%, 100%) rotate(45deg)',
      zIndex: 0,
    },
  },
  item: {
    padding: '6px 18px 6px 8px',
    '&:first-child': {
      paddingTop: 12,
      overflow: 'hidden',
      borderRadius: '5px 5px 0 0',
    },
    '&:last-child': {
      overflow: 'hidden',
      borderRadius: '5px 5px 0 0',
    },
    '&:nth-child(3)': {
      paddingBottom: 12
    }
  },
  list: {
    padding: 0
  },
  divider: {
    margin: '0 6px'
  },
  linkIcon: {
    fontSize: theme.spacing(3),
    transform: 'rotate(45deg)'
  },
  box: {
    border: 1,
    height: 16,
    width: 100,
    backgroundColor: theme.palette.frequent.lightSilver,
    fontSize: 12,
    borderRadius: 6,
    marginRight: 5
  }
}),);

export default useStyles;
