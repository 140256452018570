import { supportedLocales } from './Constants';

const trimStart = (string: string, trim: string): string => {
  if (string.startsWith(trim)) {
    return string.substring(trim.length);
  }
  return string;
};

export const getURLParam = (name: string): any => {
  let query = window.location.search;
  if (query === '') {
    query = window.location.hash?.split('?')[1];
  }
  const params = new URLSearchParams(query);
  return params.get(name);
};

export const extractLocaleFromPath = (path: string) => {
  let resolvedPath = path;
  while (resolvedPath[0] === '/') {
    resolvedPath = trimStart(resolvedPath, '/');
  }
  const possibleLocales = supportedLocales;
  const nepLocales = [
    'cs',
    'de',
    'it',
    'es',
    'zh',
    'fa',
    'id',
    'ms',
    'th',
    'vi',
    'pt',
  ];
  possibleLocales.push(...nepLocales);
  return possibleLocales.find((x) => resolvedPath.startsWith(x));
};

export const pathWithOrWithoutLocale = (
  path: string,
  locale: string | undefined,
): string => (locale ? `/${locale}${path}` : path);
