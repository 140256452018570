import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  paper: {
    marginTop: 10,
    padding: 15,
  },
  notificationSubject: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.primary.main,
    paddingBottom: 17,
    textAlign: 'left',
  },
  // notificationBody: {
  //   borderRadius: 10,
  //   boxShadow: '0 10px 20px 0 rgba(103, 126, 172, 0.16)',
  //   backgroundColor: theme.palette.frequent.reportMenuButton,
  //   textAlign: 'left',
  //   color: theme.palette.frequent.davyGrey,
  //   marginTop: 20,
  //   marginBottom: 10,
  //   padding: 15
  // },
  notificationStatus: {
    textAlign: 'left',
    fontSize: 14,
    marginBottom: 10,
  }
}));
