import { instanceApi } from '../../../services';
import { ClientListEmailReportRequest } from './ClientListModel';

export const getClientsReport = (partnershipIds: number[], currency: string[]): Promise<any> => instanceApi.post(
  'api/AnalysisReport/partnersClientsReport', {
    currencies: currency,
    partnershipIds
  }
);

export const sendEmailClientsOverviewReport = (data: ClientListEmailReportRequest): Promise<any> => instanceApi.post('api/analysisreport/sendemailclientsoverviewreport', data);
