import { CircleFlag } from 'react-circle-flags';
import React from 'react';

export const languages: any[] = [
  {
    value: 'en',
    label: 'English',
    icon: <CircleFlag countryCode="gb" style={{ height: 'inherit' }} />,
  },
  {
    value: 'ja',
    label: 'Japanese',
    icon: <CircleFlag countryCode="jp" style={{ height: 'inherit' }} />,
  },
];
