export enum AddItemResult {
  SUCCESS = 1,
  FAILURE,
  VALIDATION_ERROR,
  PRIMARY_KEY_VIOLATION,
  DUPLICATED_VALUE
}

export enum UpdateItemResult {
  SUCCESS= 1,
  FAILURE,
  VALIDATION_ERROR,
  CONCURRENCY_VIOLATION
}

export enum DeleteItemResult {
  SUCCESS = 1,
  FAILURE,
  CONCURRENCY_VIOLATION,
  OTHER
}

export enum SugarResultStatus {
  SUCCESS=1,
  FAILURE,
  DISABLED,
  NOCHANGES
}
