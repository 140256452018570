/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Grid, InputAdornment, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from './styles';
import { PartnerPartnership } from '../../../models';
import { useCommonStyles } from '../../../styles';
import { useApiError, useAppData } from '../../../providers';
import {
  CheckIcon,
  ColumnsSelect,
  FieldLabel,
  MultiSelectControl,
  Spinner,
} from '../../index';
import { getPartnerPartnerships } from '../../../pages';
import { getPartnersLogins } from './ReportGeneralToolbarWithFiltersService';
import { getLocale } from '../../../utils';

interface Props {
  fromDate: Date;
  toDate: Date;
  columns: string[];
  selectedColumns: string[];
  handleFromDateChange: (date: Date) => void;
  handleToDateChange: (date: Date) => void;
  handleButtonClick?: () => void;
  handleSelectedColumnsChange: (selected: string[]) => void;
  handlePartnershipsChange: (newOptions: string[]) => void;
  handleLoginsChange: (newOptions: string[]) => void;
}

export const ReportGeneralToolbarWithFilters: FC<Props> = ({
  fromDate,
  toDate,
  columns,
  selectedColumns,
  handleFromDateChange,
  handleToDateChange,
  handleButtonClick,
  handlePartnershipsChange,
  handleSelectedColumnsChange,
  handleLoginsChange,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { addError } = useApiError();
  const { translations, userData, locale } = useAppData();
  const [loading, setLoading] = useState<boolean>(false);
  const [partnerships, setPartnerships] = useState<PartnerPartnership[]>([]);
  const [selectedPartnerships, setSelectedPartnerships] = useState<string[]>([
    'All',
  ]);
  const [logins, setLogins] = useState<any[]>([]);
  const [selectedLogins, setSelectedLogins] = useState<string[]>(['All']);
  const [translation, setTranslation] = useState<any>([]);
  const [showDateError, setShowDateError] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(true);
  const [from, setFrom] = useState<Date>(fromDate);
  const [to, setTo] = useState<Date>(toDate);
  const [localeString, setLocaleString] = useState<string>('en');
  const [maxDate, setMaxDate] = useState<Date>(toDate);
  const [minDate, setMinDate] = useState<Date | undefined>();

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const user = JSON.parse(userData);
    if (user.accounts[0].role.hasExtDateRangeRight) {
      setMinDate(undefined);
    } else {
      setMinDate(fromDate);
    }
  }, [fromDate, userData]);

  useEffect(() => {
    if (locale) {
      setLocaleString(locale);
    }
  }, [locale]);

  const getLogins = useCallback(
    async (partnershipCodes: string[]) => {
      const userLoginData = JSON.parse(userData);
      try {
        setLoading(true);
        const response = await getPartnersLogins(
          moment(from).format('YYYY-MM-DD'),
          moment(to).format('YYYY-MM-DD'),
          partnershipCodes,
          userLoginData.partnerId,
        );
        setLogins(response.data);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setLoading(false);
      }
    },
    [from, to],
  );

  const getPartnerships = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPartnerPartnerships(true);
      setPartnerships(response.data);
      await getLogins([]);
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getPartnerships();
    })();
  }, []);

  const requestReport = useCallback(() => {
    if (handleButtonClick) {
      handleButtonClick();
      setShowButton(false);
    }
  }, [handleButtonClick]);

  const setToDate = (value: Date) => {
    if (moment(value, 'DD-MM-YYYY').isBefore(moment(fromDate, 'DD-MM-YYYY'))) {
      setShowDateError(true);
    } else {
      setShowDateError(false);
      handleToDateChange(value);
    }
    setTo(value);
    setShowButton(true);
  };

  const setFromDate = (value: Date) => {
    setFrom(value);

    if (showButton) {
      let newDate: Date;
      const today = new Date();

      const user = JSON.parse(userData);

      if (user.accounts[0].role.hasExtDateRangeRight) {
        newDate = today;
        setMinDate(undefined);
      } else if (
        value.getMonth() !== today.getMonth() ||
        value.getFullYear() !== today.getFullYear()
      ) {
        newDate = new Date(value.getFullYear(), value.getMonth() + 1, 0);
        setMinDate(value);
      } else {
        newDate = today;
        setMinDate(value);
      }

      handleToDateChange(newDate);
      setTo(newDate);
      setMaxDate(newDate);
      setShowButton(true);

      if (moment(value, 'DD-MM-YYYY').isAfter(moment(toDate, 'DD-MM-YYYY'))) {
        setShowDateError(true);
      } else {
        setShowDateError(false);
        handleFromDateChange(value);
        setShowButton(true);
      }
    } else {
      handleFromDateChange(value);
      setShowButton(true);
    }
  };

  const handlePartnershipSelectChange = useCallback(
    async (event) => {
      let options = event.target.value as string[];
      if (options.length === 0) {
        options = ['All'];
      }

      if (options.length > 1) {
        if (options.includes('All')) {
          if (options[options.length - 1] === 'All') {
            options = options.filter((option) => option === 'All');
          } else {
            options = options.filter((option) => option !== 'All');
          }
        }
      }

      setSelectedPartnerships(options);
      await getLogins(options.includes('All') ? [] : options);
      handlePartnershipsChange(options);
    },
    [handlePartnershipsChange, getLogins],
  );

  const handleLoginSelectChange = useCallback(
    async (event) => {
      let options = event.target.value as string[];
      if (options.length === 0) {
        options = ['All'];
      }

      if (options.length > 1) {
        if (options.includes('All')) {
          if (options[options.length - 1] === 'All') {
            options = options.filter((option) => option === 'All');
          } else {
            options = options.filter((option) => option !== 'All');
          }
        }
      }

      setSelectedLogins(options);
      handleLoginsChange(options);
    },
    [handleLoginsChange],
  );

  const renderValueCustomCondition = useCallback(
    (value: string[]) => {
      if (value[0] === 'All') {
        return [translation.all];
      }

      return false;
    },
    [translation.all],
  );

  return (
    <Grid container className={classes.toolbar}>
      <Spinner isLoading={loading} />
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={getLocale(localeString)}
      >
        <Grid item className={classes.dateFields}>
          <Grid container direction="column" alignContent="flex-start">
            <FieldLabel label={translation.text_1109} isRequired />
            <Grid container direction="row">
              <DatePicker
                autoOk
                className={`${classes.fromDate} ${
                  showDateError ? classes.fromDateError : ''
                }`}
                // views={['day', 'month', 'year']}
                format="dd MMMM yyyy"
                inputVariant="outlined"
                disableFuture
                value={from}
                okLabel={translation.text_903}
                cancelLabel={translation.text_28}
                InputProps={{
                  margin: 'dense',
                  startAdornment: (
                    <InputAdornment position="start">
                      {translation.text_6443}:
                    </InputAdornment>
                  ),
                }}
                onChange={(value: any) => setFromDate(value)}
              />
              <DatePicker
                autoOk
                className={`${classes.toDate} ${
                  showDateError ? classes.toDateError : ''
                }`}
                value={to}
                // views={['month', 'year']}
                format="dd MMMM yyyy"
                inputVariant="outlined"
                okLabel={translation.text_903}
                cancelLabel={translation.text_28}
                onChange={(value: any) => setToDate(value)}
                minDate={minDate}
                maxDate={maxDate}
                disableFuture
                InputProps={{
                  margin: 'dense',
                  startAdornment: (
                    <InputAdornment position="start">
                      {translation.text_6444}:
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {showDateError && (
              <Grid item className={classes.errorMessage}>
                {translation.text_6445}
              </Grid>
            )}
          </Grid>
        </Grid>
        {showButton && (
          <Grid item>
            <Button
              variant="contained"
              className={`${commonClasses.buttonContained} ${classes.button}`}
              onClick={requestReport}
              disabled={showDateError}
            >
              {translation.text_6446}
            </Button>
          </Grid>
        )}
        {!showButton && (
          <>
            <Grid item style={{ marginRight: 10 }} className={classes.desktop}>
              <FieldLabel label={translation.text_6447} />
              <MultiSelectControl
                renderValueCustomCondition={renderValueCustomCondition}
                value={selectedPartnerships}
                onChange={(event: any) => handlePartnershipSelectChange(event)}
                className={classes.multiSelect}
              >
                <MenuItem
                  className={
                    selectedPartnerships.includes('All')
                      ? classes.selectedOption
                      : classes.option
                  }
                  value="All"
                >
                  <CheckIcon />
                  <span>{translation.all}</span>
                </MenuItem>
                {partnerships.map((partnership) => (
                  <MenuItem
                    className={
                      selectedPartnerships.includes(
                        partnership.partnershipCode as string,
                      )
                        ? classes.selectedOption
                        : classes.option
                    }
                    key={partnership.partnershipCode}
                    value={
                      partnership.partnershipCode
                        ? partnership.partnershipCode
                        : ''
                    }
                  >
                    <CheckIcon />
                    <span>{partnership.partnershipCode}</span>
                  </MenuItem>
                ))}
              </MultiSelectControl>
            </Grid>
            {selectedPartnerships.length > 0 && (
              <Grid item className={classes.desktop}>
                <FieldLabel label={translation.text_6001} />
                <MultiSelectControl
                  renderValueCustomCondition={renderValueCustomCondition}
                  value={selectedLogins}
                  onChange={(event: any) => handleLoginSelectChange(event)}
                  className={classes.multiSelect}
                >
                  <MenuItem
                    className={
                      selectedLogins.includes('All')
                        ? classes.selectedOption
                        : classes.option
                    }
                    value="All"
                  >
                    <CheckIcon />
                    <span>{translation.all}</span>
                  </MenuItem>
                  {logins.map((login) => (
                    <MenuItem
                      className={
                        selectedLogins.includes(login.key)
                          ? classes.selectedOption
                          : classes.option
                      }
                      key={login.key}
                      value={login.key}
                    >
                      <CheckIcon />
                      <span>{login.key}</span>
                    </MenuItem>
                  ))}
                </MultiSelectControl>
              </Grid>
            )}
            <Grid
              item
              style={{ marginLeft: 'auto' }}
              className={classes.desktop}
            >
              <ColumnsSelect
                reportColumns={columns}
                selectedColumns={selectedColumns}
                handleSelectColumns={handleSelectedColumnsChange}
              />
            </Grid>
            <Grid container direction="row" className={classes.mobile}>
              <Grid item>
                <FieldLabel label={translation.text_6447} />
                <MultiSelectControl
                  renderValueCustomCondition={renderValueCustomCondition}
                  value={selectedPartnerships}
                  onChange={(event: any) =>
                    handlePartnershipSelectChange(event)
                  }
                  className={classes.multiSelect}
                >
                  <MenuItem
                    className={
                      selectedPartnerships.includes('All')
                        ? classes.selectedOption
                        : classes.option
                    }
                    value="All"
                  >
                    <CheckIcon />
                    <span>{translation.all}</span>
                  </MenuItem>
                  {partnerships.map((partnership) => (
                    <MenuItem
                      className={
                        selectedPartnerships.includes(
                          partnership.partnershipCode as string,
                        )
                          ? classes.selectedOption
                          : classes.option
                      }
                      key={partnership.partnershipCode}
                      value={
                        partnership.partnershipCode
                          ? partnership.partnershipCode
                          : ''
                      }
                    >
                      <CheckIcon />
                      <span>{partnership.partnershipCode}</span>
                    </MenuItem>
                  ))}
                </MultiSelectControl>
              </Grid>
              {selectedPartnerships.length > 0 && (
                <Grid item>
                  <FieldLabel label={translation.text_6001} />
                  <MultiSelectControl
                    renderValueCustomCondition={renderValueCustomCondition}
                    value={selectedLogins}
                    onChange={(event: any) => handleLoginSelectChange(event)}
                    className={classes.multiSelect}
                  >
                    <MenuItem
                      className={
                        selectedLogins.includes('All')
                          ? classes.selectedOption
                          : classes.option
                      }
                      value="All"
                    >
                      <CheckIcon />
                      <span>{translation.all}</span>
                    </MenuItem>
                    {logins.map((login) => (
                      <MenuItem
                        className={
                          selectedLogins.includes(login.key)
                            ? classes.selectedOption
                            : classes.option
                        }
                        key={login.key}
                        value={login.key}
                      >
                        <CheckIcon />
                        <span>{login.key}</span>
                      </MenuItem>
                    ))}
                  </MultiSelectControl>
                </Grid>
              )}
              <Grid item className={classes.columns}>
                <ColumnsSelect
                  reportColumns={columns}
                  selectedColumns={selectedColumns}
                  handleSelectColumns={handleSelectedColumnsChange}
                />
              </Grid>
            </Grid>
          </>
        )}
      </MuiPickersUtilsProvider>
    </Grid>
  );
};
