/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Card, CardActions, CardHeader, Container, FormControl, Grid, MenuItem, Select, Typography, TypographyProps } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import { defaultLocale, extractLocaleFromPath, languages, PATH_LOGIN_AS_REPRESENTATIVE, supportedLocales } from '../../utils';
import { AngleDownIcon } from '../../components';
import { useApiError, useAppData, useLoading } from '../../providers';
import { getTranslationsByBrokerId } from '../Login/LoginService';

const cardHeaderTextAlignStyles: Partial<TypographyProps> = { align: 'center' };

export const WelcomePage: FC = () => {
  const classes = useStyles();
  const { setIsLoading } = useLoading();
  const { addError } = useApiError();
  const { saveTranslations, saveLocale } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const [selectedLocale, setSelectedLocale] = useState<any>(languages[0]);
  const location = useLocation();
  const locale = extractLocaleFromPath(location.pathname);

  const getTranslationList = useCallback(
    async (newLocale: string) => {
      try {
        setIsLoading(true);
        const brokerID = process.env.REACT_APP_BROKER_ID;
        const translations = await getTranslationsByBrokerId(newLocale, brokerID);
        saveTranslations(JSON.stringify(translations.data));
        setTranslation(translations.data);
        saveLocale(newLocale);
      } catch (error) {
        addError(JSON.stringify(error.response));
      } finally {
        setIsLoading(false);
      }
    },
    [saveTranslations, saveLocale, addError, setIsLoading],
  );

  const handleLocaleChange = useCallback(
    async (event: React.ChangeEvent<{ value: unknown }>) => {
      const selectedLanguage = languages.find((item) => item.value === (event.target.value as string)) || languages[0];
      setSelectedLocale(selectedLanguage);
      await getTranslationList(selectedLanguage.value);
    },
    [getTranslationList],
  );

  useEffect(() => {
    (async () => {
      if (locale && supportedLocales.includes(locale)) {
        const selectedLanguage = languages.find((item) => item.value === locale) || languages[0];
        setSelectedLocale(selectedLanguage);
        await getTranslationList(locale);
      } else {
        await getTranslationList(defaultLocale);
      }
    })();
  }, [locale]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="row-reverse">
        <FormControl variant="standard" className={classes.languageSelect}>
          <Select
            onChange={handleLocaleChange}
            renderValue={(value: any) => (
              <div className={classes.languageItem}>
                <span className={classes.languageSelectedItem}>{value.icon}</span>
                {value.value}
              </div>
            )}
            autoWidth
            inputProps={{
              IconComponent: AngleDownIcon,
            }}
            value={selectedLocale}
            disableUnderline
          >
            {languages.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <span className={classes.languageSelectItem}>{item.icon}</span>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid container direction="column" className={classes.content}>
        <Container maxWidth="md" component="main" className={classes.container}>
          <Typography component="h1" variant="h2" align="center">
            {translation.text_1642}
          </Typography>
        </Container>
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="center" justify="center">
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardHeader title={translation.text_1643} titleTypographyProps={cardHeaderTextAlignStyles} subheaderTypographyProps={cardHeaderTextAlignStyles} className={classes.cardHeader} />
                <CardActions>
                  <Button fullWidth variant="contained" className={classes.loginBtn} href={`${process.env.REACT_APP_URL_NEP_AUTH_LINK}?language=${selectedLocale.value}`}>
                    {translation.text_1645}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardHeader title={translation.text_1644} titleTypographyProps={cardHeaderTextAlignStyles} subheaderTypographyProps={cardHeaderTextAlignStyles} className={classes.cardHeader} />
                <CardActions>
                  <Button fullWidth variant="contained" className={classes.loginBtn} component={Link} to={PATH_LOGIN_AS_REPRESENTATIVE}>
                    {translation.text_1645}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};
