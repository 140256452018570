import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    webTitle: {
      marginBottom: 5,

      '@media (max-width: 915px)': {
        display: 'none',
      },
    },
    title: {
      color: theme.palette.text.primary,
      textAlign: 'left',
      fontSize: 28,
      fontWeight: 500,
      lineHeight: '1.334',
      letterSpacing: '0em',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    printButton: {
      height: 40,
      paddingLeft: 22,
      paddingRight: 22,
      backgroundColor: theme.palette.changeable.exportButtons,
      color: theme.palette.common.white,
      justifyContent: 'center',
      borderRadius: 20,

      '&:hover': {
        backgroundColor: theme.palette.changeable.hover,
        color: theme.palette.common.white,
      },

      '@media (max-width: 915px)': {
        marginRight: 0,
        width: '95px !important;',
        height: 29,
        padding: '7px 9px',
      },
      '@media (max-width: 360px)': {
        width: '75px !important;',
        height: 29,
        padding: '7px 9px',
      },
    },
    mobileTitle: {
      color: theme.palette.text.primary,
      textAlign: 'left',

      '@media (min-width: 916px)': {
        display: 'none',
      },
    },
  }),
);
