import ReactToPrint from 'react-to-print';
import React, {
  FC, useRef, ReactNode, useState, useEffect
} from 'react';
import { Button, Grid } from '@material-ui/core';
import { ComponentToPrint } from './ComponentToPrint';
import { useStyles } from '../Reports/ReportTitle/styles';
import { useCommonStyles } from '../../styles';

interface Props {
    title: string
    children: ReactNode,
    columnNumber: number
    isEnabled: boolean
    translation:any
}

export const PrintButton: FC<Props> = ({
  title, children, columnNumber, isEnabled, translation
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const componentRef = useRef(null);
  const [pageSize, setPageSize] = useState<string>('portrait');

  useEffect(() => {
    setPageSize(columnNumber > 5 ? 'landscape' : 'portrait');
  }, [columnNumber]);

  return (
    <Grid item style={{ marginRight: 13 }}>
      <ReactToPrint
        trigger={() => (
          <Button
            size="small"
            variant="contained"
            className={`${commonClasses.buttonContained} ${classes.printButton}`}
            disabled={!isEnabled}
          >{translation.text_6413}
          </Button>
        )}
        content={() => componentRef.current}
        pageStyle={`
            @page { 
                size: ${pageSize}; 
            }
        
            @media all {
                .pagebreak {
                    display: none;
                }
            }

            @media print {
                .pagebreak {
                    page-break-before: always;
                }
            }
        `}
      />
      <ComponentToPrint
        ref={componentRef}
        title={title}
        columnNumber={columnNumber}
      >{children}
      </ComponentToPrint>
    </Grid>
  );
};
