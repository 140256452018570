import React, { FC } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useStyles } from './styles';

interface PanelProps {
  title: string
  panelName: string
  selectedPanel: string
  callBackFunction: ((clickedTitle: string) => void)
  value?: number
  active?: number
  dormant?: number
  translation: any
}

export const CensusPanel: FC<PanelProps> = ({
  title, selectedPanel, callBackFunction, value, active, dormant, panelName, translation
}) => {
  const classes = useStyles();

  return (
    <Paper
      className={`${classes.paper} ${selectedPanel === panelName ? classes.paperSelected : ''}`}
      onClick={() => callBackFunction(panelName)}
    >
      <div className={classes.rectangle} />
      <Grid container direction="column">
        <Grid item className={classes.paperTitles}>{title}</Grid>
        <Grid item className={classes.values}>{value}</Grid>
        <Grid container direction="row" justify="space-between" className={classes.activeInactiveRow}>
          <Grid item>
            {translation.text_6280}:
            <p className={classes.bold}> {active}</p>
          </Grid>
          <Grid item>
            {translation.text_6281}:
            <p className={classes.bold}> {dormant}</p>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
