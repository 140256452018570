import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Grid, IconButton, Paper, Tab, Tabs, Typography
} from '@material-ui/core';
import { getIBPartnershipClientsAndAccounts } from '../../PartnersHierarchyService';
import {
  ArrowBackIcon, ExportButtons, MobileTableView, TabPanel
} from '../../../../components';
import { a11yProps } from '../../../../utils';
import { useStyles } from './styles';
import { ValueLabel } from '../../../../models';

interface Props {
  data: any,
  translation: any
  handleBack: () => void
}

export const MobileClientList: FC<Props> = ({ data, handleBack, translation }) => {
  const classes = useStyles();
  const clientsColumns = useMemo(() => [translation.text_6394, translation.text_6395, translation.text_6396, translation.text_6397, translation.text_6398, translation.text_6399], [translation]);
  const accountsColumns = useMemo(() => [translation.text_6400, translation.text_6396, translation.text_6397, translation.text_6398, translation.text_6399], [translation]);

  const [clientsTableData, setClientsTableData] = useState<any>([]);
  const [accountsTableData, setAccountsTableData] = useState<any>([]);
  const [columnNames, setColumnNames] = useState<string[]>(clientsColumns);
  const [value, setValue] = useState<number>(0);

  const formatReportData = useCallback((clients: any[]) => {
    const clientsResult: any[] = [];
    const accountsResult: any[] = [];

    clients.forEach((client) => {
      const item = {
        clientName: client.name,
        login: Array.from(new Set(client.accounts.map((account: any) => account.login))),
        platformGroup: Array.from(new Set(client.accounts.map((account: any) => account.platformGroup))),
        currency: Array.from(new Set(client.accounts.map((account: any) => account.currency))),
        platform: Array.from(new Set(client.accounts.map((account: any) => account.platform))),
        tradingAccountType: Array.from(new Set(client.accounts.map((account: any) => account.tradingAccountType)))
      };

      client.accounts.forEach((account: any) => {
        const accountItem = {
          login: account.login,
          platformGroup: account.platformGroup,
          currency: account.currency,
          platform: account.platform,
          tradingAccountType: account.tradingAccountType
        };
        accountsResult.push(accountItem);
      });

      clientsResult.push(item);
    });

    setClientsTableData(clientsResult);
    setAccountsTableData(accountsResult);
  }, [setClientsTableData]);

  const getClientsAndAccounts = useCallback(async (partnershipId: number) => {
    const response = await getIBPartnershipClientsAndAccounts(partnershipId);
    formatReportData(response.data);
  }, [formatReportData]);

  useEffect(() => {
    if (data !== null) {
      (async () => {
        await getClientsAndAccounts(data.partnershipId);
      })();
    }
  }, [getClientsAndAccounts, data]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);

    if (newValue === 1) {
      setColumnNames(accountsColumns);
    } else {
      setColumnNames(clientsColumns);
    }
  };

  const renderClientRows = useCallback((): any[] => {
    const result: any[] = [];

    if (clientsTableData.length === 0) {
      const rowData: ValueLabel[] = [];
      columnNames.forEach((columnName) => {
        rowData.push({
          value: '',
          label: columnName
        });
      });

      result.push(rowData);
    }

    clientsTableData.forEach((row: any) => {
      const rowData: ValueLabel[] = [];

      rowData.push({
        label: 'Client Name',
        value: row.clientName
      });

      rowData.push({
        label: 'Trading Account/Login',
        value: row.login.map((l: any) => (<span key={l}>{l}<br /></span>))
      });

      rowData.push({
        label: 'Platform Group',
        value: row.platformGroup.map((p: any) => (<span key={p}>{p}<br /></span>))
      });

      rowData.push({
        value: 'Currency',
        label: row.currency.map((c: any) => (<span key={c}>{c}<br /></span>))
      });

      rowData.push({
        label: 'Platform',
        value: row.platform.map((p: any) => (<span key={p}>{p}<br /></span>))
      });

      rowData.push({
        label: 'Trading Account Type',
        value: row.tradingAccountType.map((t: any) => (<span key={t}>{t}<br /></span>))
      });

      result.push(rowData);
    });

    return result;
  }, [clientsTableData, columnNames]);

  const renderAccountRows = useCallback((): any[] => {
    const result: any[] = [];

    if (accountsTableData.length === 0) {
      const rowData: ValueLabel[] = [];
      columnNames.forEach((columnName) => {
        rowData.push({
          value: '',
          label: columnName
        });
      });

      result.push(rowData);
    }

    accountsTableData.forEach((row: any) => {
      const rowData: ValueLabel[] = [];

      rowData.push({
        label: 'Trading Account/Login',
        value: row.login
      });

      rowData.push({
        label: 'Platform Group',
        value: row.platformGroup
      });

      rowData.push({
        label: 'Currency',
        value: row.currency
      });

      rowData.push({
        label: 'Platform',
        value: row.platform
      });

      rowData.push({
        label: 'Trading Account Type',
        value: row.tradingAccountType
      });

      result.push(rowData);
    });

    return result;
  }, [accountsTableData, columnNames]);

  const renderClientsTableData = (): string[][] => {
    const rows: string[][] = [];
    clientsTableData.forEach((row: any) => {
      const tableRow: string[] = [row.clientName, row.login, row.platformGroup, row.currency, row.platform, row.tradingAccountType];
      rows.push(tableRow);
    });

    return rows;
  };

  const renderAccountsTableData = (): string[][] => {
    const rows: string[][] = [];
    accountsTableData.forEach((row: any) => {
      const tableRow: string[] = [row.login, row.platformGroup, row.currency, row.platform, row.tradingAccountType];
      rows.push(tableRow);
    });

    return rows;
  };

  return (
    <Grid container direction="column" className={classes.root} alignItems="flex-start">
      <Grid container direction="row" alignContent="flex-start" alignItems="center" className={classes.titleContainer}>
        <Typography variant="h5" className={classes.title}>
          <IconButton onClick={handleBack}><ArrowBackIcon /></IconButton>
          {`Hierarchy - Partnership ${data.partnershipCode}`}
        </Typography>
        <div style={{ marginLeft: 43 }}>{`Clients(${data.clientsNumber}) and Trading Accounts (${data.accountsNumber})`}</div>
      </Grid>
      <Grid container direction="row" justify="center">
        <ExportButtons
          exportFileName={value === 0 ? 'Clients' : 'Trading Accounts'}
          columns={columnNames}
          exportTableData={value === 0 ? renderClientsTableData() : renderAccountsTableData()}
          isEnabled
        />
      </Grid>
      <Paper className={classes.paper}>
        <p style={{ textAlign: 'left' }}><b>Show By</b></p>
        <Tabs value={value} onChange={handleChange} className={classes.tabs} variant="fullWidth">
          <Tab className={classes.clientTab} label="Clients" {...a11yProps(0)} />
          <Tab className={classes.accountTab} label="Trading Accounts" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <MobileTableView list={renderClientRows()} grouped={false} hasNextButton={false} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MobileTableView list={renderAccountRows()} grouped={false} hasNextButton={false} />
        </TabPanel>
      </Paper>
    </Grid>
  );
};
