import moment from 'moment';
import { useCallback } from 'react';
import { getSelectedColumnsInEn } from 'src/utils';
import { useApiError, useAppData, useLoading } from 'src/providers';
import { useSnackbar } from 'notistack';
import { sendEmailByReportId } from '../CommissionsByClientService';
import { reportEnColumnsReportId } from '../CommissionsByClientModel';

const useSendEmailByClient = ({
  toDate,
  fromDate,
  selected,
  reportColumns,
  selectedLanguage,
  partnershipCode,
}: {
  toDate: Date;
  fromDate: Date;
  selected: string[];
  reportColumns: any[];
  selectedLanguage?: string;
  partnershipCode: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading } = useLoading();
  const { userData, translations } = useAppData();
  const { addError } = useApiError();

  return useCallback(async () => {
    const loginData = JSON.parse(userData);
    setIsLoading(true);
    try {
      const tableColumns = getSelectedColumnsInEn(
        reportEnColumnsReportId,
        selected,
        reportColumns,
      );
      await sendEmailByReportId({
        partnershipCode,
        PartnerId: loginData.PartnerID,
        Month: fromDate.getMonth() + 1,
        Year: fromDate.getFullYear(),
        Day: fromDate.getDate(),
        EndMonth: toDate.getMonth() + 1,
        EndYear: toDate.getFullYear(),
        EndDay: toDate.getDate(),
        ConvertToCurrency: 'USD',
        RequestorAccountGuid: loginData.defaultAccount,
        reportEmailRequest: {
          email: '',
          startDate: moment(fromDate).format('YYYY-MM-DD'),
          endDate: moment(toDate).format('YYYY-MM-DD'),
          includeTotal: true,
          language: selectedLanguage || 'en',
          tableColumns,
        },
      });

      enqueueSnackbar(`${JSON.parse(translations).text_1640} ${loginData.userEmail}.`, {
        variant: 'success',
      });
    } catch (error) {
      addError(JSON.stringify(error.response));
    } finally {
      setIsLoading(false);
    }
  }, [
    userData,
    partnershipCode,
    fromDate,
    toDate,
    setIsLoading,
    selected,
    reportColumns,
    selectedLanguage,
    enqueueSnackbar,
    translations,
    addError,
  ]);
};

export default useSendEmailByClient;
