import React, {
  ChangeEvent, FC, useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Divider, FormControl, Grid, MenuItem
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useStyles } from './styles';
import {
  GreenArrowIcon, GreyLineIcon, RedArrowIcon, DateRangeSelector, SelectControl
} from '../../../../../../components';
import { useAppData } from '../../../../../../providers';
import { getLocale } from '../../../../../../utils';

interface Props {
  subTitle: string
  partnership?: string;
  volume: string;
  volumeLots: string;
  increased?: number;
  decreased?: number;
  increasedLots?: number;
  decreasedLots?: number;
  fromDate?: Date
  toDate?: Date
  translation:any
  handleFromDateChange?: (date: Date) => void
  handleToDateChange?: (date: Date) => void
}

export const TitlePanel: FC<Props> = ({
  subTitle, volume, volumeLots, increased, decreased, increasedLots,
  decreasedLots, partnership, fromDate, toDate, handleFromDateChange, translation,
  handleToDateChange
}) => {
  const classes = useStyles();
  const defaultFilter = 'placeholder';
  const { locale } = useAppData();
  const [localeString, setLocaleString] = useState<string>('en');
  const [selectedFilter, setSelectedFilter] = useState<string>(defaultFilter);
  const [from, setFrom] = useState<Date | null>(fromDate || null);
  const [to, setTo] = useState<Date | null>(toDate || null);
  const quickFilter = useMemo(() => [
    translation.text_1606,
    translation.text_1607,
    translation.text_1608,
    translation.text_1609,
    translation.text_1610,
    translation.text_1611,
    translation.text_1612,
    translation.text_1613,
    translation.text_1614
  ], [translation]);

  const handleFilterChange = useCallback(async (event: ChangeEvent<{value: any}>) => {
    const filter = event.target.value;
    setSelectedFilter(filter);

    let startDate = new Date();
    let endDate = new Date();

    if (filter === quickFilter[0]) {
      startDate = moment().startOf('isoWeek').toDate();
      endDate = moment().endOf('isoWeek').toDate() > new Date() ? new Date() : moment().endOf('isoWeek').toDate();
    }

    if (filter === quickFilter[1]) {
      startDate = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
      endDate = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
    }

    if (filter === quickFilter[2]) {
      startDate = moment().startOf('month').toDate();
      endDate = moment().endOf('month').toDate() > new Date() ? new Date() : moment().endOf('month').toDate();
    }

    if (filter === quickFilter[3]) {
      startDate = moment().subtract(1, 'month').startOf('month').toDate();
      endDate = moment().subtract(1, 'month').endOf('month').toDate();
    }

    if (filter === quickFilter[4]) {
      startDate = moment().subtract(6, 'months').toDate();
    }

    if (filter === quickFilter[5]) {
      startDate = moment().subtract(12, 'months').toDate();
    }

    if (filter === quickFilter[6]) {
      startDate = moment().startOf('year').toDate();
    }

    if (filter === quickFilter[7]) {
      startDate = moment().subtract(1, 'year').startOf('year').toDate();
      endDate = moment().subtract(1, 'year').endOf('year').toDate();
    }

    if (filter === quickFilter[8]) {
      startDate = moment([2010]).startOf('year').toDate();
    }

    if (handleToDateChange) {
      handleToDateChange(endDate);
    }

    if (handleFromDateChange) {
      handleFromDateChange(startDate);
    }

    setFrom(null);
    setTo(null);
  }, [handleToDateChange, handleFromDateChange, quickFilter]);

  useEffect(() => {
    if (locale) {
      setLocaleString(locale);
    }
  }, [locale]);

  return (
    <Grid item xs={12}>
      <Grid container direction="column" alignContent="flex-start">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(localeString)}>
          <Grid container direction="row" alignItems="center" justify="space-between" className={classes.dataRow}>
            <Grid item>
              <Grid container direction="row">
                <Grid item>
                  <Grid container direction="column" alignItems="flex-start" style={{ marginRight: 20 }}>
                    <Grid item className={classes.mainTitle}>{translation.text_6369}</Grid>
                    {volume !== '0' ? (
                      <>
                        <Grid item className={classes.valueTitle}>{volume}</Grid>
                        <Grid item className={classes.changeTitle}>
                          {increased
                            ? (<><GreenArrowIcon className={classes.changeTitleIcon} /> {translation.text_6370} <b>{increased} %</b> {subTitle}</>)
                            : (decreased
                              ? (<><RedArrowIcon className={classes.changeTitleIcon} /> {translation.text_6371} <b>{decreased} %</b> {subTitle}</>)
                              : (<><GreyLineIcon className={classes.noChangeTitleIcon} /> {translation.text_6372} {subTitle}</>)
                            )}
                        </Grid>
                      </>
                    ) : (
                      <Grid item className={classes.emptyMessage} style={{ paddingRight: 10 }}>
                        {translation.text_6373}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="flex-start" alignContent="flex-start">
                    <Grid item className={classes.mainTitle}>{translation.text_6374}</Grid>
                    {volumeLots !== '0' ? (
                      <>
                        <Grid item className={classes.valueTitle}>{volumeLots}</Grid>
                        <Grid item className={classes.changeTitle}>
                          {increasedLots
                            ? (<><GreenArrowIcon className={classes.changeTitleIcon} /> {translation.text_6370} <b>{increasedLots} %</b> {subTitle}</>)
                            : (decreasedLots
                              ? (<><RedArrowIcon className={classes.changeTitleIcon} /> {translation.text_6371} <b>{decreasedLots} %</b> {subTitle}</>)
                              : (<><GreyLineIcon className={classes.noChangeTitleIcon} /> {translation.text_6372} {subTitle}</>)
                            )}
                        </Grid>
                      </>
                    ) : (
                      <Grid item className={classes.emptyMessage}>
                        {translation.text_6375}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="flex-end">
                {partnership && partnership !== 'All' && (<Grid item className={classes.partnershipTitle}><b>{translation.text_6330}:</b> {partnership}</Grid>)}
                <Grid container direction="row">
                  <DateRangeSelector
                    translation={translation}
                    fromDate={from}
                    toDate={to}
                    handleFromDateChange={(date) => {
                      setFrom(date);
                      if (handleFromDateChange) {
                        handleFromDateChange(date);
                      }
                      setSelectedFilter(defaultFilter);
                    }}
                    handleToDateChange={(date) => {
                      setTo(date);
                      if (handleToDateChange) {
                        handleToDateChange(date);
                      }
                      setSelectedFilter(defaultFilter);
                    }}
                  />
                  {partnership && partnership !== 'All' && (
                    <Grid item style={{ marginLeft: 15 }}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <SelectControl
                          value={selectedFilter}
                          onChange={handleFilterChange}
                          className={classes.selectBox}
                        >
                          <MenuItem value={defaultFilter} className={classes.optionSelector} disabled>
                            {translation.text_1605}
                          </MenuItem>
                          {quickFilter.map((filter) => (
                            <MenuItem className={classes.optionSelector} key={filter} value={filter}>{filter}</MenuItem>))}
                        </SelectControl>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" alignItems="center" className={classes.mobileDataRow}>
            {partnership && partnership !== 'All' && (<Grid item className={classes.partnershipTitle}><b>{translation.text_6330}:</b> {partnership}</Grid>)}
            <DateRangeSelector
              translation={translation}
              fromDate={from}
              toDate={to}
              handleFromDateChange={(date) => {
                setFrom(date);
                if (handleFromDateChange) {
                  handleFromDateChange(date);
                }
                setSelectedFilter(defaultFilter);
              }}
              handleToDateChange={(date) => {
                setTo(date);
                if (handleToDateChange) {
                  handleToDateChange(date);
                }
                setSelectedFilter(defaultFilter);
              }}
            />
            {partnership && partnership !== 'All' && (
              <Grid item style={{ marginTop: 10, marginBottom: 10 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <SelectControl
                    value={selectedFilter}
                    onChange={handleFilterChange}
                    className={classes.selectBox}
                  >
                    <MenuItem value={defaultFilter} className={classes.optionSelector} disabled>
                      {translation.text_1605}
                    </MenuItem>
                    {quickFilter.map((filter) => (
                      <MenuItem className={classes.optionSelector} key={filter} value={filter}>{filter}</MenuItem>))}
                  </SelectControl>
                </FormControl>
              </Grid>
            )}
            <Grid container direction="row" justify="space-between">
              <Grid item xs={6}>
                <Grid container direction="column" alignItems="flex-start">
                  <Grid item className={classes.mainTitle}>{translation.text_6369}</Grid>
                  {volume !== '0' ? (
                    <>
                      <Grid item className={classes.valueTitle}>{volume}</Grid>
                      <Grid item className={classes.changeTitle}>
                        {increased
                          ? (<><GreenArrowIcon className={classes.changeTitleIcon} /> {translation.text_6370} <b>{increased} %</b> {subTitle}</>)
                          : (decreased
                            ? (<><RedArrowIcon className={classes.changeTitleIcon} /> {translation.text_6371} <b>{decreased} %</b> {subTitle}</>)
                            : (<><GreyLineIcon className={classes.noChangeTitleIcon} /> {translation.text_6372} {subTitle}</>)
                          )}
                      </Grid>
                    </>
                  ) : (
                    <Grid item className={classes.emptyMessage} style={{ paddingRight: 10 }}>
                      {translation.text_6373}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="column" alignItems="flex-start" alignContent="flex-start">
                  <Grid item className={classes.mainTitle}>{translation.text_6374}</Grid>
                  {volumeLots !== '0' ? (
                    <>
                      <Grid item className={classes.valueTitle}>{volumeLots}</Grid>
                      <Grid item className={classes.changeTitle}>
                        {increasedLots
                          ? (<><GreenArrowIcon className={classes.changeTitleIcon} /> {translation.text_6370} <b>{increasedLots} %</b> {subTitle}</>)
                          : (decreasedLots
                            ? (<><RedArrowIcon className={classes.changeTitleIcon} /> {translation.text_6371} <b>{decreasedLots} %</b> {subTitle}</>)
                            : (<><GreyLineIcon className={classes.noChangeTitleIcon} /> {translation.text_6372} {subTitle}</>)
                          )}
                      </Grid>
                    </>
                  ) : (
                    <Grid item className={classes.emptyMessage}>
                      {translation.text_6375}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={12}>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
      </Grid>
    </Grid>
  );
};
