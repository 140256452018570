export interface TradingRequestModel {
  partnershipCodes: string[];
  fromDate: string;
  toDate: string;
  pageNumber: number;
  login?: string[];
}

export interface TradingActivitiesReportData {
  id: number;
  closePrice: number;
  closeTime: string;
  commissionUSD: number;
  login: number;
  lot: number;
  openPrice: number;
  openTime: string;
  order: number;
  partnershipCode: string;
  profitUSD: number;
  sl: number;
  swapUSD: number;
  symbol: string;
  tp: number;
  type: string;
  volumeUSD: number;
}

export interface TradingActivitiesEmailReportRequest {
  reportPartnershipClientsTradingRequest: {
    fromDate: string;
    toDate: string;
    partnershipCodes: string[];
    pageNumber: number;
    login: string[];
  };
  reportEmailRequest: {
    email: null | string;
    includeTotal: boolean;
    tableColumns: string[];
    language: string | undefined;
    startDate: string | null;
    endDate: string | null;
  };
}

export const reportEnColumns = [
  'partnership',
  'login',
  'deal no',
  'open time',
  'type',
  'symbol',
  'volume',
  'open price',
  'close time',
  'close price',
  's/l',
  't/p',
  'commission',
  'swap',
  'profit',
];
