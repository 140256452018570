import React, { FC, useCallback, useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Grid, InputAdornment } from '@material-ui/core';
import moment from 'moment';
import { useAppData } from '../../../providers';
import { useStyles } from './styles';
import { FieldLabel } from '../../Form';
import { useCommonStyles } from '../../../styles';
import { getLocale } from '../../../utils';

interface Props {
  fromDate: Date;
  toDate: Date;
  handleFromDateChange: (date: Date) => void;
  handleToDateChange: (date: Date) => void;
  showButton?: boolean;
  handleButtonClick?: () => void;
}

export const ReportsGeneralToolbar: FC<Props> = ({
  fromDate,
  toDate,
  handleFromDateChange,
  handleToDateChange,
  handleButtonClick,
  showButton,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { translations, locale, userData } = useAppData();
  const [translation, setTranslation] = useState<any>([]);
  const [showDateError, setShowDateError] = useState<boolean>(false);
  const [from, setFrom] = useState<Date>(fromDate);
  const [to, setTo] = useState<Date>(toDate);
  const [maxDate, setMaxDate] = useState<Date>(toDate);
  const [minDate, setMinDate] = useState<Date | undefined>();

  useEffect(() => {
    setTranslation(JSON.parse(translations));
  }, [translations]);

  useEffect(() => {
    const user = JSON.parse(userData);
    if (user.accounts[0].role.hasExtDateRangeRight) {
      setMinDate(undefined);
    } else {
      setMinDate(fromDate);
    }
  }, [fromDate, userData]);

  const requestReport = useCallback(() => {
    if (handleButtonClick) {
      handleButtonClick();
    }
  }, [handleButtonClick]);

  const setToDate = (value: Date) => {
    if (moment(value, 'DD-MM-YYYY').isBefore(moment(fromDate, 'DD-MM-YYYY'))) {
      setShowDateError(true);
    } else {
      setShowDateError(false);
    }
    handleToDateChange(value);
    setTo(value);
  };

  const setFromDate = (value: Date) => {
    setFrom(value);

    if (showButton) {
      let newDate: Date;
      const today = new Date();

      const user = JSON.parse(userData);

      if (user.accounts[0].role.hasExtDateRangeRight) {
        newDate = today;
        setMinDate(undefined);
      } else if (
        value.getMonth() !== today.getMonth() ||
        value.getFullYear() !== today.getFullYear()
      ) {
        newDate = new Date(value.getFullYear(), value.getMonth() + 1, 0);
        setMinDate(value);
      } else {
        newDate = today;
        setMinDate(value);
      }

      handleToDateChange(newDate);
      setTo(newDate);
      setMaxDate(newDate);

      if (moment(value, 'DD-MM-YYYY').isAfter(moment(toDate, 'DD-MM-YYYY'))) {
        setShowDateError(true);
      } else {
        setShowDateError(false);
        handleFromDateChange(value);
      }
    } else {
      handleFromDateChange(value);
    }
  };

  return (
    <Grid container className={classes.toolbar}>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={getLocale(locale || 'en')}
      >
        <Grid item className={classes.dateFields}>
          <Grid container direction="column" alignContent="flex-start">
            <FieldLabel label={translation.text_1109} isRequired />
            <Grid container direction="row">
              <DatePicker
                autoOk
                className={`${classes.fromDate} ${
                  showDateError ? classes.fromDateError : ''
                }`}
                // views={['month', 'year']}
                format="dd MMMM yyyy"
                inputVariant="outlined"
                disableFuture
                okLabel={translation.text_903}
                cancelLabel={translation.text_28}
                value={from}
                InputProps={{
                  margin: 'dense',
                  startAdornment: (
                    <InputAdornment position="start">
                      {translation.text_6414}:
                    </InputAdornment>
                  ),
                }}
                onChange={(value: any) => setFromDate(value)}
              />
              <DatePicker
                autoOk
                className={`${classes.toDate} ${
                  showDateError ? classes.toDateError : ''
                }`}
                value={to}
                // views={['month', 'year']}
                format="dd MMMM yyyy"
                inputVariant="outlined"
                onChange={(value: any) => setToDate(value)}
                disableFuture
                minDate={minDate}
                maxDate={maxDate}
                okLabel={translation.text_903}
                cancelLabel={translation.text_28}
                InputProps={{
                  margin: 'dense',
                  startAdornment: (
                    <InputAdornment position="start">
                      {translation.text_6415}:
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {showDateError && (
              <Grid item className={classes.errorMessage}>
                {translation.text_6416}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          {showButton && (
            <Button
              color="primary"
              variant="contained"
              className={`${commonClasses.buttonContained} ${classes.button}`}
              onClick={requestReport}
              disabled={showDateError}
            >
              {translation.text_6417}
            </Button>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  );
};
