import React, { FC, useState, useEffect, useMemo } from 'react';
import useSendEmailByClient from 'src/pages/Reports/AllClientsAndTrading/hooks/useSendEmailByClient';
import { ReportDetailsModel } from 'src/models';
import { ReportDetailsDialog } from '../../../../../../../components';
import { DetailedTable } from './DetailedTable';
import { dateObjectToMonthYearString } from '../../../../../../../utils';
import { useAppData } from '../../../../../../../providers';

interface Props {
  toDate: Date;
  fromDate: Date;
  translation: any;
  title: any;
  subTitle: any;
  dateFrom: Date;
  data: ReportDetailsModel[];
  partnershipCode: string;
  trigger: number | undefined;
  handleCancel: () => void;
}

export const CommissionDialog: FC<Props> = ({
  toDate,
  fromDate,
  title,
  subTitle,
  dateFrom,
  data,
  trigger,
  handleCancel,
  translation,
  partnershipCode,
}) => {
  const { locale } = useAppData();
  const [groupBy, setGroupBy] = useState<string | undefined>(undefined);
  const columnNames = useMemo(
    () => [
      translation.text_6549,
      translation.text_6550,
      translation.text_6551,
      translation.text_6552,
      translation.text_6553,
      translation.text_6554,
      translation.text_6555,
    ],
    [translation],
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>(columnNames);
  const [language, setLanguage] = useState<string>();

  useEffect(() => {
    setLanguage(locale);
  }, [locale]);

  const handleSelectChange = (value: string[]) => {
    setSelectedColumns(value);
  };

  useEffect(() => {
    if (columnNames.length === 7) {
      setSelectedColumns(columnNames.slice(0, 7));
    }
  }, [columnNames]);

  const exportReportName = (): string => {
    let reportName = translation.text_6035;
    reportName += ` ${dateObjectToMonthYearString(dateFrom)}`;

    return reportName;
  };

  const renderTableData = (): string[][] => {
    const rows: string[][] = [];
    data.forEach((row) => {
      const tableRow: string[] = [
        row.Login ? row.Login.toString() : '-',
        row.Group ? row.Group : '-',
        row.Symbol ? row.Symbol : '-',
        row.RebateUSDLocal || row.RebateUSDLocal === 0
          ? row.RebateUSDLocal.toFixed(2)
          : '-',
        row.VolumeUSD || row.VolumeUSD === 0 ? row.VolumeUSD.toFixed(2) : '-',
        row.VolumeLots || row.VolumeLots === 0
          ? row.VolumeLots.toFixed(2)
          : '-',
        row.PaymentUSD || row.PaymentUSD === 0
          ? row.PaymentUSD.toFixed(2)
          : '-',
      ];
      rows.push(tableRow);
    });

    return rows;
  };

  const sendEmail = useSendEmailByClient({
    toDate,
    fromDate,
    selected: selectedColumns,
    reportColumns: columnNames,
    partnershipCode,
    selectedLanguage: language,
  });

  return (
    <ReportDetailsDialog
      trigger={trigger}
      title={title}
      subTitle={subTitle}
      reportColumns={columnNames}
      selectedColumns={selectedColumns}
      exportTableData={renderTableData()}
      exportReportName={exportReportName()}
      handleGroupBy={setGroupBy}
      handleSelectColumns={handleSelectChange}
      handleCancel={handleCancel}
      sendEmail={sendEmail}
    >
      <DetailedTable
        translation={translation}
        groupByOption={groupBy}
        columnNames={columnNames}
        selectedColumns={selectedColumns}
        rows={data}
      />
    </ReportDetailsDialog>
  );
};
